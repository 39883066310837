import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  blogData: any;
  recentBlogData: any;
  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {
    this.titleService.setTitle('貸款知識 | Credit Pro');
    this.metaService.updateTag({
      name: 'description',
      content: '貸款知識 | Credit Pro',
    });

    // Set Open Graph tags
    this.metaService.updateTag({ property: 'og:title', content: '貸款知識 | Credit Pro' });
    this.metaService.updateTag({
      property: 'og:description',
      content: '貸款知識 | Credit Pro',
    });
    this.metaService.updateTag({ property: 'og:url', content: 'https://creditprohk.com/blog' });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/footer-logo.png',
    });

    var canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      (canonicalLink as HTMLLinkElement).href = 'https://creditprohk.com/blog';
    } else {
      var newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      newCanonicalLink.href = 'https://creditprohk.com/blog';

      document.head.appendChild(newCanonicalLink);
    }
  }
  async ngOnInit() {
    try {
      const response = await fetch('https://backend.creditprohk.com/en/api/recent-blog/');

      if (response.ok) {
        console.log(response);
        this.recentBlogData = await response.json();
        console.log(this.recentBlogData);
      } else {
        alert('Login Failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    try {
      const response = await fetch('https://backend.creditprohk.com/en/api/blog-management/');

      if (response.ok) {
        console.log(response);
        this.blogData = await response.json();
        console.log(this.blogData);
      } else {
        alert('Login Failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
