import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QaComponent {
  faqs: FAQItem[] = [
    {
      question: '60歲以上或退休人士可否申請？',
      showAnswer: true,
      answerHtml:
        '<p>可以。若物業業主年過60歲，只須一位有工作能力的人士作為擔保人便可申請貸款。當然，若借款人能証明雖年過60歲但仍有工作能力，則有機會不用擔保人便可申請貸款。</p>',
      safeAnswer: '',
    },
    {
      question: '送契樓可否申請按揭？',
      showAnswer: false,
      answerHtml:
        '<p>一般情況下，送契滿3年便可辦理按揭。我們會根據不同個案情況會彈性處理客戶要求，即使少於3年亦可接受申請。</p>',
      safeAnswer: '',
    },
    {
      question: '若是聯名擁有物業，是否需要所有業主同意才能申請物業貸款？',
      showAnswer: false,
      answerHtml:
        '<p>申請Credit Pro的物業貸款，只需由其中一位業主／受託人辦理申請手續即可。並於簽署正式貸款合約時，只需由其中一位業主以完成貸款手續。</p>',
      safeAnswer: '',
    },
    // Add more FAQ items here
  ];
  constructor(private sanitizer: DomSanitizer) {
    this.faqs.forEach((faq) => {
      faq.safeAnswer = this.sanitizer.bypassSecurityTrustHtml(faq.answerHtml);
    });
  }
  toggleAnswer(faq: FAQItem) {
    this.faqs.forEach((item) => {
      if (item !== faq) {
        item.showAnswer = false; // Close all other FAQ items
      }
    });

    faq.showAnswer = !faq.showAnswer;
  }
}
interface FAQItem {
  question: string;
  // answer: string;
  showAnswer: boolean;
  answerHtml: string;
  safeAnswer: SafeHtml;
}
