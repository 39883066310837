import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CookieService } from '../../cookie.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  loanAmount: any;
  loanTennure: any;
  minMonthlyInstallment: any;
  maxMonthlyInstallment: any;
  minInterest: any;
  maxInterest: any;

  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
    private cookieService: CookieService,
  ) {
    this.titleService.setTitle('AI貸款計算機 | 網上即批低息私人貸款 | Credit Pro');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Credit Pro提供AI貸款計算機，你可以輕鬆計算並申請網上即批的低息私人貸款。Credit Pro是您可信賴的貸款合作夥伴，我們根據你的需求和經濟情況，訂製一套專屬的還款計劃，即刻使用我們的貸款計算機，享受快速、方便的智能貸款服務。',
    });

    // Set Open Graph tags
    this.metaService.updateTag({ property: 'og:title', content: 'AI貸款計算機 | 網上即批低息私人貸款 | Credit Pro' });
    this.metaService.updateTag({
      property: 'og:description',
      content:
        'Credit Pro提供AI貸款計算機，你可以輕鬆計算並申請網上即批的低息私人貸款。Credit Pro是您可信賴的貸款合作夥伴，我們根據你的需求和經濟情況，訂製一套專屬的還款計劃，即刻使用我們的貸款計算機，享受快速、方便的智能貸款服務。',
    });
    this.metaService.updateTag({ property: 'og:url', content: 'https://creditprohk.com/' });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/footer-logo.png',
    });

    var canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      (canonicalLink as HTMLLinkElement).href = 'https://creditprohk.com/';
    } else {
      var newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      newCanonicalLink.href = 'https://creditprohk.com/';

      document.head.appendChild(newCanonicalLink);
    }
    if (window.location.href.includes('?gclid=')) {
      const addTrackUrl = window.location.href.split('?gclid=')[1];
      this.cookieService.setSessionCookie('addUrl', addTrackUrl);
    }
    this.loanAmount = 10000;
    this.loanTennure = 6;
    this.minInterest = 0.1;
    this.maxInterest = 0.28;

    this.calculateMonthlyInstallment();
  }

  ngOnInit() {
    // localStorage.removeItem('id_token');
    // localStorage.removeItem('user_id');
    // localStorage.removeItem('token_timestamp');
    this.ensureVideoPlays();
  }
  private ensureVideoPlays(): void {
    const video = document.querySelector('video');

    if (!video) return;

    const promise = video.play();
    if (promise !== undefined) {
      promise
        .then(() => {
          // Autoplay started
        })
        .catch((error) => {
          // Autoplay was prevented.
          video.muted = true;
          video.play();
        });
    }
  }

  // loanAmountPinFormatter(value: number) {
  //   //alert(value);
  //   return `${value}`;
  // }

  onLoanAmountChange(ev: Event) {
    const inputElement = ev.target as HTMLInputElement;
    if (inputElement) {
      this.loanAmount = inputElement.value;
    }
    setTimeout(() => {
      this.calculateMonthlyInstallment();
    }, 100);
  }

  onLoanTennureChange(ev: Event) {
    const inputElement = ev.target as HTMLInputElement;
    if (inputElement) {
      this.loanTennure = inputElement.value;
    }
    if (this.loanTennure == 0) {
      this.loanTennure = 1;
    }
    setTimeout(() => {
      this.calculateMonthlyInstallment();
    }, 100);
  }

  calculateMonthlyInstallment() {
    var minMonthlyInterest = this.minInterest / 12;
    var maxMonthlyInterest = this.maxInterest / 12;

    this.minMonthlyInstallment =
      (this.loanAmount * minMonthlyInterest) / (1 - (1 + minMonthlyInterest) ** (-1 * this.loanTennure));
    this.minMonthlyInstallment = Math.round(this.minMonthlyInstallment);

    this.maxMonthlyInstallment =
      (this.loanAmount * maxMonthlyInterest) / (1 - (1 + maxMonthlyInterest) ** (-1 * this.loanTennure));
    this.maxMonthlyInstallment = Math.round(this.maxMonthlyInstallment);
  }
}
