import { Component } from '@angular/core';

@Component({
  selector: 'app-mortgage-benefits',
  templateUrl: './mortgage-benefits.component.html',
  styleUrls: ['./mortgage-benefits.component.scss']
})
export class MortgageBenefitsComponent {

}
