<div class="forgot-password">
  <app-header />
  <div class="forgot-password__cont container">
    <app-form>
      <div class="forgot-password__form">
        <div class="forgot-password__form__header">
          <h2 class="forgot-password__form__title">忘記密碼</h2>
        </div>
        <form [formGroup]="forgotpasswordForm">
          <div
            [ngClass]="{
              input: true,
              input___error:
                phoneNumber &&
                (forgotpasswordForm.get('phone_number')?.hasError('required') ||
                  forgotpasswordForm.get('phone_number')?.hasError('pattern'))
            }"
          >
            <label><span>*</span>手機號碼</label>
            <div class="input__row">
              <input
                type="text"
                placeholder="香港手機號碼，如66668888"
                id="phone_number"
                formControlName="phone_number"
                (input)="resetError()"
                [readOnly]="otpVerified"
              />
              <!-- <a class="input__row__btn" *ngIf="registrationForm.get('phone_number')!.valid" (click)="sendOTP()"
                      >Send Otp</a> -->
            </div>
            <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('phone_number')?.hasError('required')">
              請輸入正確的手機號碼
            </p>
            <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('phone_number')?.hasError('pattern')">
              請輸入8位數字的香港手機號碼
            </p>
          </div>
          <div
            [ngClass]="{
              input: true,
              input___error:
                otp &&
                (forgotpasswordForm.get('otp')?.hasError('required') ||
                  forgotpasswordForm.get('otp')?.hasError('pattern'))
            }"
            *ngIf="otpSent && forgotpasswordForm.get('phone_number')!.valid"
          >
            <label><span>*</span>發送短信驗證碼</label>
            <div class="input__row">
              <input
                type="text"
                placeholder="******"
                formControlName="otp"
                (input)="resetError()"
                [readOnly]="otpVerified"
              />
              <!-- <a class="input__row__btn" (click)="verifyOTP()">Verify Otp</a> -->
            </div>
            <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('otp')?.hasError('required')">
              發送請輸入正確的短信驗證碼
            </p>
            <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('otp')?.hasError('pattern')">
              請輸入4位數字的短信驗證碼
            </p>
          </div>
          <input type="button" value="發送短信驗證碼" class="form-submit-btn" (click)="sendOTP()" *ngIf="!otpSent" />
          <input
            type="button"
            value="立即驗證"
            class="form-submit-btn"
            (click)="verifyOTP()"
            *ngIf="otpSent && forgotpasswordForm.get('phone_number')!.valid && !otpVerified"
          />

          <ng-container *ngIf="otpVerified">
            <div
              [ngClass]="{
                input: true,
                input___error: password && forgotpasswordForm.get('password')?.hasError('required')
              }"
            >
              <label><span>*</span>密碼</label>
              <input type="password" placeholder="**************" formControlName="password" (input)="resetError()" />
              <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('password')?.hasError('required')">請輸入密碼</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: confirmPassword && forgotpasswordForm.get('confirmPassword')?.hasError('required')
              }"
            >
              <label><span>*</span>確認密碼</label>
              <input
                type="password"
                placeholder="**************"
                formControlName="confirmPassword"
                (input)="resetError()"
              />
              <p class="input__errorMsg" *ngIf="forgotpasswordForm.get('confirmPassword')?.hasError('required')">
                請再次輸入密碼
              </p>
            </div>
            <input type="button" value="重置密碼" class="form-submit-btn" (click)="onReset()" />
          </ng-container>
        </form>
      </div>
    </app-form>
  </div>
  <app-footer />
</div>
