<div class="blog__cont__recent">
  <div class="blog__cont__recent__title">Recent Blogs</div>
  <div class="blog__cont__recent__list">
    <a
      [routerLink]="['/blog', blogData[0].alias]"
      *ngIf="blogData !== undefined && blogData.length > 0"
      class="blog__box"
    >
      <img [src]="blogData[0].thumbnail_image" alt="blog image" />
      <div class="blog__box__detail">
        <div class="blog__box__detail__top">
          <div class="blog__box__detail__top__author">
            {{ blogData[0].author_name }} • {{ blogData[0].created.substr(0, 10) }}
          </div>
          <div class="blog__box__detail__top__row">
            <h3 class="blog__box__detail__top__row__title">{{ blogData[0].title }}</h3>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path
                d="M7.66699 17L17.667 7M17.667 7H7.66699M17.667 7V17"
                stroke="#FDFDFD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p>{{ blogData[0].discription }}</p>
        </div>
        <div class="blog__box__detail__bottom">
          <p class="blog__box__detail__bottom__tag blog__box__detail__bottom__tag___green">
            {{ blogData[0].blog_tag1 }}
          </p>
          <p class="blog__box__detail__bottom__tag blog__box__detail__bottom__tag___yellow">
            {{ blogData[0].blog_tag2 }}
          </p>
          <p class="blog__box__detail__bottom__tag blog__box__detail__bottom__tag___red">{{ blogData[0].blog_tag3 }}</p>
        </div>
      </div>
    </a>
    <div class="blog__cont__recent__list__right">
      <a
        [routerLink]="['/blog', blogData[1].alias]"
        class="blog__horizontalBox"
        *ngIf="blogData !== undefined && blogData.length > 1"
      >
        <img [src]="blogData[1].thumbnail_image" alt="blog image" />
        <div class="blog__horizontalBox__detail">
          <div class="blog__horizontalBox__detail__top">
            <div class="blog__horizontalBox__detail__top__author">
              {{ blogData[1].author_name }} • {{ blogData[1].created.substr(0, 10) }}
            </div>
            <div class="blog__horizontalBox__detail__top__row">
              <h3 class="blog__horizontalBox__detail__top__row__title">{{ blogData[1].title }}</h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path
                  d="M7.66699 17L17.667 7M17.667 7H7.66699M17.667 7V17"
                  stroke="#FDFDFD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p>{{ blogData[1].discription }}</p>
          </div>
          <div class="blog__horizontalBox__detail__bottom">
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___green">
              {{ blogData[1].blog_tag1 }}
            </p>
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___yellow">
              {{ blogData[1].blog_tag2 }}
            </p>
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___red">
              {{ blogData[1].blog_tag3 }}
            </p>
          </div>
        </div>
      </a>
      <a
        [routerLink]="['/blog', blogData[2].alias]"
        class="blog__horizontalBox"
        *ngIf="blogData !== undefined && blogData.length > 2"
      >
        <img [src]="blogData[2].thumbnail_image" alt="blog image" />
        <div class="blog__horizontalBox__detail">
          <div class="blog__horizontalBox__detail__top">
            <div class="blog__horizontalBox__detail__top__author">
              {{ blogData[2].author_name }} • {{ blogData[2].created.substr(0, 10) }}
            </div>
            <div class="blog__horizontalBox__detail__top__row">
              <h3 class="blog__horizontalBox__detail__top__row__title">{{ blogData[2].title }}</h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path
                  d="M7.66699 17L17.667 7M17.667 7H7.66699M17.667 7V17"
                  stroke="#FDFDFD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p>{{ blogData[2].discription }}</p>
          </div>
          <div class="blog__horizontalBox__detail__bottom">
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___green">
              {{ blogData[2].blog_tag1 }}
            </p>
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___yellow">
              {{ blogData[2].blog_tag2 }}
            </p>
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___red">
              {{ blogData[2].blog_tag3 }}
            </p>
          </div>
        </div>
      </a>
      <!-- <div class="blog__horizontalBox">
        <img src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/blog-recent.jpg" alt="blog image" />
        <div class="blog__horizontalBox__detail">
          <div class="blog__horizontalBox__detail__top">
            <div class="blog__horizontalBox__detail__top__author">Alec Written • 17 Jan 2022</div>
            <h3 class="blog__horizontalBox__detail__top__row__title">Bill Walsh leadership lessons</h3>
            <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?</p>
          </div>
          <div class="blog__horizontalBox__detail__bottom">
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___green">
              Design
            </p>
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___red">
              Presentation
            </p>
          </div>
        </div>
      </div>
      <div class="blog__horizontalBox">
        <img src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/blog-recent.jpg" alt="blog image" />
        <div class="blog__horizontalBox__detail">
          <div class="blog__horizontalBox__detail__top">
            <div class="blog__horizontalBox__detail__top__author">Alec Written • 17 Jan 2022</div>
            <h3 class="blog__horizontalBox__detail__top__row__title">Migrating to Linear 101</h3>
            <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?</p>
          </div>
          <div class="blog__horizontalBox__detail__bottom">
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___green">
              Design
            </p>
            <p class="blog__horizontalBox__detail__bottom__tag blog__horizontalBox__detail__bottom__tag___yellow">
              Research
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
