<!-- <button (click)="clearSignature()">Clear</button>
<button (click)="saveSignature()">Save</button> -->
<div class="loan-detail__cont__loans">
  <h2 class="loan-detail__cont__loans__title">已完成貸款</h2>
  <canvas #signaturePad width="300" height="150"></canvas>

  <div class="loan-detail__cont__loans__bottom">
    <a class="loan-detail__cont__loans__bottom__clear" (click)="clearSignature()">Clear Signature</a>
    <a class="loan-detail__cont__loans__bottom__submit" (click)="saveSignature()">Submit Contract</a>
  </div>
</div>
