import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private tokenService: TokenService,
  ) {}
  ngOnInit() {
    if (this.tokenService.isTokenExpired()) {
      // Token has expired
      localStorage.removeItem('user_id');
      localStorage.removeItem('id_token');
      localStorage.removeItem('token_timestamp');
      this.router.navigate(['/registration']);
    }
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.tokenService.isTokenExpired()) {
      // Token has expired
      localStorage.removeItem('user_id');
      localStorage.removeItem('id_token');
      localStorage.removeItem('token_timestamp');
      this.router.navigate(['/registration']);
    } else {
      const idToken = localStorage.getItem('id_token');
      const userId = localStorage.getItem('user_id');

      if (idToken && userId) {
        return true; // Allow access to the route
      } else {
        // Redirect to the login page
        this.router.navigate(['/login']);
      }
    }
  }
}
