import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HomeComponent } from './pages/home/home.component';
import { DebtComponent } from './pages/debt/debt.component';
import { FaqComponent } from './pages/faq/faq.component';
import { PersonalLoanComponent } from './pages/personal-loan/personal-loan.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ApplyComponent } from './pages/apply/apply.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { AuthGuard } from './auth.guard';
import { AccountComponent } from './pages/account/account.component';
import { LoanDetailComponent } from './pages/loan-detail/loan-detail.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { MortgageComponent } from './pages/mortgage/mortgage.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { MoneyLendersComponent } from './pages/money-lenders/money-lenders.component';
import { CalculatorComponent } from './pages/calculator/calculator.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'debt', component: DebtComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'personal-loan', component: PersonalLoanComponent },
  { path: 'login', component: LoginComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'apply', component: ApplyComponent, canActivate: [AuthGuard] },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-detail', component: BlogDetailComponent },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'account/:accountId', component: LoanDetailComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'mortgage', component: MortgageComponent },
  { path: 'blog/:id', component: BlogDetailComponent },
  { path: 'privacyPolicy', component: PrivacyPolicyComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'moneyLenders', component: MoneyLendersComponent },
  { path: 'calculator', component: CalculatorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
