<div class="registration">
  <app-header />
  <div class="registration__cont container">
    <app-form>
      <div class="registration__form">
        <div class="registration__form__header">
          <h2 class="registration__form__title">極速申請</h2>
          <p class="registration__form__subTitle">登記後完成簡單問題，即批貸款，網上簽約無需現身</p>
        </div>
        <form [formGroup]="registrationForm">
          <div
            [ngClass]="{
              input: true,
              input___error: name && registrationForm.get('name')?.hasError('required')
            }"
          >
            <label><span>*</span>姓名</label>
            <div class="input__row">
              <input type="text" placeholder="請輸入名稱" id="name" formControlName="name" (input)="resetError()" />
            </div>
            <p class="input__errorMsg" *ngIf="registrationForm.get('name')?.hasError('required')">姓名為必填項</p>
          </div>
          <div
            [ngClass]="{
              input: true,
              input___error:
                phoneNumber &&
                (registrationForm.get('phone_number')?.hasError('required') ||
                  registrationForm.get('phone_number')?.hasError('pattern'))
            }"
          >
            <label><span>*</span>手機號碼</label>
            <div class="input__row">
              <input
                type="text"
                placeholder="香港手機號碼，如66668888"
                id="phone_number"
                formControlName="phone_number"
                (input)="resetError()"
                [readOnly]="otpVerified"
              />
              <!-- <a class="input__row__btn" *ngIf="registrationForm.get('phone_number')!.valid" (click)="sendOTP()"
                >Send Otp</a> -->
            </div>
            <p class="input__errorMsg" *ngIf="registrationForm.get('phone_number')?.hasError('required')">
              請輸入正確的手機號碼
            </p>
            <p class="input__errorMsg" *ngIf="registrationForm.get('phone_number')?.hasError('pattern')">
              請輸入8位數字的香港手機號碼
            </p>
          </div>
          <div
            [ngClass]="{
              input: true,
              input___error:
                otp &&
                (registrationForm.get('otp')?.hasError('required') || registrationForm.get('otp')?.hasError('pattern'))
            }"
            *ngIf="otpSent && registrationForm.get('phone_number')!.valid"
          >
            <label><span>*</span>短信驗證碼</label>
            <div class="input__row">
              <input
                type="text"
                placeholder="******"
                formControlName="otp"
                (input)="resetError()"
                [readOnly]="otpVerified"
              />
              <!-- <a class="input__row__btn" (click)="verifyOTP()">Verify Otp</a> -->
            </div>
            <p class="input__errorMsg" *ngIf="registrationForm.get('otp')?.hasError('required')">
              請輸入正確的短信驗證碼
            </p>
            <p class="input__errorMsg" *ngIf="registrationForm.get('otp')?.hasError('pattern')">
              請輸入4位數字的短信驗證碼
            </p>
          </div>
          <input type="button" value="發送短信驗證碼" class="form-submit-btn" *ngIf="sendingOtp && !otpSent" />
          <input
            type="button"
            value="發送短信驗證碼"
            class="form-submit-btn"
            (click)="sendOTP()"
            *ngIf="!sendingOtp && !otpSent"
          />
          <input
            type="button"
            value="立即驗證"
            class="form-submit-btn"
            (click)="verifyOTP()"
            *ngIf="otpSent && registrationForm.get('phone_number')!.valid && !otpVerified"
          />

          <!-- <ng-container *ngIf="otpVerified">
            <div
              [ngClass]="{
                input: true,
                input___error: password && registrationForm.get('password')?.hasError('required')
              }"
            >
              <label><span>*</span>密碼</label>
              <input type="password" placeholder="**************" formControlName="password" (input)="resetError()" />
              <p class="input__errorMsg" *ngIf="registrationForm.get('password')?.hasError('required')">請輸入密碼</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error:
                  passwordMismatch || (confirmPassword && registrationForm.get('confirmPassword')?.hasError('required'))
              }"
            >
              <label><span>*</span>確認密碼</label>
              <input
                type="password"
                placeholder="**************"
                formControlName="confirmPassword"
                (input)="resetError()"
              />
              <p class="input__errorMsg" *ngIf="registrationForm.get('confirmPassword')?.hasError('required')">
                請再次輸入密碼
              </p>
              <p class="input__errorMsg" *ngIf="passwordMismatch">Passwords do not match.</p>
            </div>
            <input type="button" value="立即登記" class="form-submit-btn" (click)="onSubmit()" />
          </ng-container> -->
          <div class="registration__form__signup">已有賬號? <a href="/login">立即登入</a></div>
        </form>
      </div>
    </app-form>
  </div>
  <app-footer />
</div>
