<div class="header container" [ngClass]="{ header: true, container: true, header___openMenu: showMenu === true }">
  <a href="/" class="header__logo"
    ><img src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/logo.svg" alt="logo" width="188" height="52"
  /></a>
  <div class="header__menu">
    <a href="/">主頁</a>
    <a href="/about-us">關於我們</a>
    <a href="/debt">債務一筆清</a>
    <a href="/mortgage">樓宇按揭</a>
    <a href="/personal-loan">私人貸款</a>
    <a href="/faq">常見問題</a>
    <a href="/blog">博客</a>
    <div class="header__menu__mob" *ngIf="showMenu">
      <a href="/login" class="header__menu__mob__login" *ngIf="!redirect">登入/註冊</a>
      <a href="/account" class="header__menu__mob__login" *ngIf="redirect">帳戶</a>
      <a href="/apply" class="header__menu__mob__apply">立即申請</a>
    </div>
  </div>
  <div class="header__right">
    <a href="/login" class="header__right__login" *ngIf="!redirect">登入/註冊</a>
    <a href="/account" class="header__right__login" *ngIf="redirect">帳戶</a>
    <a href="/apply" class="header__right__apply">立即申請</a>
  </div>
  <a class="header__mobIcon" (click)="showMenuFn()">
    <img
      *ngIf="!showMenu"
      src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/menu-icon-open.svg"
      width="24px"
      height="24px"
      alt="menu open"
    />
    <img
      *ngIf="showMenu"
      src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/menu-icon-close.svg"
      width="24px"
      height="24px"
      alt="menu open"
    />
  </a>
</div>
