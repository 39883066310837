import { Component, ViewEncapsulation, AfterViewInit, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-loan-detail',
  templateUrl: './loan-detail.component.html',
  styleUrls: ['./loan-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoanDetailComponent implements OnInit {
  singleLoanData: any;
  applicationData: any;
  safeUrl: any;
  data: any;
  iframeElement = document.getElementById('myIframe') as HTMLIFrameElement;
  iframeCreated = false;
  loaded: any;
  frameLoaded = false;
  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    // Initialize safeUrl as a safe empty URL, or provide a default URL as needed
    this.safeUrl = '';
    this.data;
  }
  async ngOnInit() {
    try {
      const response = await fetch('https://backend.creditprohk.com/en/api/user-contracts/', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
        },
      });

      if (response.ok) {
        const applicationId = parseInt(window.location.pathname.split('/')[2]);
        let data = await response.json();
        this.applicationData = data.filter(
          (appId: { loan_management: number }) => appId.loan_management === applicationId,
        );
        if (this.applicationData.length > 0) {
          this.data = this.applicationData[0];
          const unsafeUrl = this.applicationData[0].contract_file;
          this.safeUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${this.applicationData[0].contract_file}`;
          // const encodedContractFile = encodeURIComponent(unsafeUrl);
          // const safeUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodedContractFile}`;
          // this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl);
          // this.createIframe();
        }
      } else {
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    try {
      const response = await fetch('https://backend.creditprohk.com/en/api/loan-management/', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
        },
      });

      if (response.ok) {
        const loanId = parseInt(window.location.pathname.split('/')[2]);
        let loanData = await response.json();
        this.singleLoanData = loanData.filter((status: { id: number }) => status.id === loanId);
      } else {
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  createIframe() {
    const iframeElement = this.renderer.createElement('iframe');
    this.renderer.setAttribute(iframeElement, 'id', 'myIframe');
    this.renderer.setAttribute(iframeElement, 'width', '100%');
    this.renderer.setAttribute(iframeElement, 'height', '600px');
    this.renderer.setStyle(iframeElement, 'z-index', '9');
    this.renderer.setAttribute(iframeElement, 'src', this.safeUrl!.toString());
    this.renderer.listen(iframeElement, 'error', () => {
      this.reloadIframe();
    });
    if (iframeElement.contentWindow) {
      this.handleIframeLoad(iframeElement.contentWindow);
    } else {
      this.loaded = setInterval(() => {
        this.reloadIframe();
      }, 5000);
      this.renderer.listen(iframeElement, 'load', () => {
        this.handleIframeLoad(iframeElement.contentWindow);
      });
    }
    const container = this.elementRef.nativeElement.querySelector('#iframeContainer');
    if (container) {
      this.renderer.appendChild(container, iframeElement);
      this.iframeCreated = true;
    }
  }
  handleIframeLoad(contentWindow: Window | null): void {
    if (contentWindow && contentWindow.length && contentWindow.length > 0) {
      clearInterval(this.loaded);
      this.frameLoaded = true;
    } else {
      this.reloadIframe();
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.iframeCreated) {
        this.createIframe();
      }
    }, 1000);
  }

  reloadIframe() {
    const iframeElement = this.elementRef.nativeElement.querySelector('#myIframe') as HTMLIFrameElement;

    if (iframeElement) {
      iframeElement.src = this.safeUrl!.toString();
    }
  }
}
