<div class="benefits">
  <div class="benefits__cont container">
    <div class="benefits__box">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/benefits-1-1.png"
        alt="benefits-1"
        height="88"
        width="130"
      />
      <h2 class="benefits__box__title">一對一跟進</h2>
      <p>專人分析財務狀況重建個人信貸記錄</p>
    </div>
    <div class="benefits__box">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/benefits-2-1.png"
        alt="benefits-2"
        height="88"
        width="130"
      />
      <h2 class="benefits__box__title">MIN PAY殺手</h2>
      <p>每月MIN PAY即時還少一半避免出現入不敷支情況</p>
    </div>
    <div class="benefits__box">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/benefits-3-1.png"
        alt="benefits-3"
        height="88"
        width="130"
      />
      <h2 class="benefits__box__title">輕鬆供款</h2>
      <p>因應個人供款能力不同，我們貼心計算還款年期，從而令客戶輕鬆供款</p>
    </div>
    <div class="benefits__box">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/benefits-4-1.png"
        alt="benefits-4"
        height="88"
        width="130"
      />
      <h2 class="benefits__box__title">免查TU</h2>
      <p>免查信貸記錄即可申請</p>
    </div>
  </div>
</div>
