import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent {
  inquiryForm: FormGroup;
  phone_number = false;
  email = false;
  loan_amount = false;
  loan_tenor = false;
  success = false;
  constructor(
    private fb: FormBuilder,
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {
    this.titleService.setTitle('關於我們 | Credit Pro');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Credit Pro提供AI貸款計算機，你可以輕鬆計算並申請網上即批的低息私人貸款。Credit Pro是您可信賴的貸款合作夥伴，我們根據你的需求和經濟情況，訂製一套專屬的還款計劃，即刻使用我們的貸款計算機，享受快速、方便的智能貸款服務。',
    });

    // Set Open Graph tags
    this.metaService.updateTag({ property: 'og:title', content: '關於我們 | Credit Pro' });
    this.metaService.updateTag({
      property: 'og:description',
      content:
        'Credit Pro提供AI貸款計算機，你可以輕鬆計算並申請網上即批的低息私人貸款。Credit Pro是您可信賴的貸款合作夥伴，我們根據你的需求和經濟情況，訂製一套專屬的還款計劃，即刻使用我們的貸款計算機，享受快速、方便的智能貸款服務。',
    });
    this.metaService.updateTag({ property: 'og:url', content: 'https://creditprohk.com/about-us' });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/footer-logo.png',
    });

    // Set canonical link
    var canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      (canonicalLink as HTMLLinkElement).href = 'https://creditprohk.com/about-us';
    } else {
      var newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      newCanonicalLink.href = 'https://creditprohk.com/about-us';

      document.head.appendChild(newCanonicalLink);
    }
    this.inquiryForm = this.fb.group({
      phone_number: ['', [Validators.required, Validators.pattern(/^\d{8}$/)]],
      email: ['', [Validators.required, Validators.email]],
      loan_amount: ['', Validators.required],
      loan_tenor: ['', Validators.required],
    });
  }

  async onSubmit() {
    this.phone_number = true;
    this.email = true;
    this.loan_amount = true;
    this.loan_tenor = true;
    console.log(this.inquiryForm.value);
    if (this.inquiryForm.valid) {
      const credentials = this.inquiryForm.value;
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/inquiry_application/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(credentials),
        });

        if (response.ok) {
          this.success = true;
          const data = await response.json();
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const phoneNumberCont = this.inquiryForm.get('phone_number');
      const emailCont = this.inquiryForm.get('email');
      const loanAmtCont = this.inquiryForm.get('loan_amount');
      const loanTenorCont = this.inquiryForm.get('loan_tenor');
      if (phoneNumberCont) {
        this.phone_number = phoneNumberCont.invalid;
      }
      if (emailCont) {
        this.email = emailCont.invalid;
      }
      if (loanAmtCont) {
        this.loan_amount = loanAmtCont.invalid;
      }
      if (loanTenorCont) {
        this.loan_tenor = loanTenorCont.invalid;
      }
    }
  }
  resetError() {
    this.phone_number = false;
    this.email = false;
    this.loan_amount = false;
    this.loan_tenor = false;
  }
}
