import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-recent-blog',
  templateUrl: './recent-blog.component.html',
  styleUrls: ['./recent-blog.component.scss'],
})
export class RecentBlogComponent {
  @Input() blogData: any;
}
