import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent {
  emiForm!: FormGroup;
  result!: string;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.emiForm = this.formBuilder.group({
      amount: [null, Validators.required],
      period: [null, Validators.required],
      annualRate: [null, Validators.required],
      frequency: ['monthly', Validators.required],
    });
  }

  clearForm() {
    this.emiForm.reset();
    this.result = '';
  }

  calculateEMI() {
    if (this.emiForm.valid) {
      const calPrecent = this.emiForm.value.annualRate;
      const calType = this.emiForm.value.frequency;
      const sum = this.Cal(calPrecent, calType);
      console.log(sum);
      if (sum !== null && sum > 0) {
        this.result = `Contribution amount: HK$ ${sum}`;
      }
    }
  }

  Cal(calPrecent: number, calType: string): number | null {
    const a = parseFloat(this.emiForm.value.amount);
    const n = parseInt(this.emiForm.value.period, 10);
    let i = parseFloat(calPrecent.toString());
    let sum = 0;

    if (isNaN(n)) {
      sum = 0;
    }

    // Assuming confirmation is a global variable
    if (n > 1000000) {
      sum = 0;
    }

    i = i / (calType == 'halfMonthly' ? 24 : 12);
    var ti = i / 100;
    ti = 1 + ti;
    var tti = ti;

    for (var loopi = 1; loopi < n; loopi++) {
      tti = tti * ti;
    }

    tti = 1 / tti;
    tti = 1 - tti;
    ti = i / 100;
    tti = ti / tti;
    tti = a * tti;
    ti = tti;

    if (ti == parseFloat(tti.toFixed(0))) {
      sum = ti;
    } else if (isNaN(parseFloat(tti.toFixed(0)))) {
      sum = 0;
    }
    sum = parseFloat(ti.toFixed(0));

    if (a && n && i) {
      return sum;
    } else {
      return null; // Return null instead of false for a number | null return type
    }
  }
}
