import { Component } from '@angular/core';

@Component({
  selector: 'app-sticky-social',
  templateUrl: './sticky-social.component.html',
  styleUrls: ['./sticky-social.component.scss']
})
export class StickySocialComponent {

}
