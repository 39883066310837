import { Component } from '@angular/core';

@Component({
  selector: 'app-online-process',
  templateUrl: './online-process.component.html',
  styleUrls: ['./online-process.component.scss']
})
export class OnlineProcessComponent {

}
