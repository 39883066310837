<div class="loan-detail">
  <app-header />
  <div class="loan-detail__cont container" *ngIf="applicationData !== undefined && applicationData.length > 0">
    <div class="loan-detail__cont__top">
      <a href="/account" class="loan-detail__cont__back"
        ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 12H5" stroke="#CAAFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 19L5 12L12 5" stroke="#CAAFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Back</a
      >

      <div class="account__cont__loans" *ngIf="singleLoanData !== undefined && singleLoanData.length > 0">
        <div class="account__cont__loans__cont" *ngFor="let application of singleLoanData">
          <app-account-detail [childData]="application" [hideBtn]="true" />
        </div>
      </div>
    </div>
    <div class="loan-detail__cont__loans" *ngIf="applicationData !== undefined && applicationData.length > 0">
      <h2 class="loan-detail__cont__loans__title">持續貸款</h2>
      <div id="iframeContainer">
        <h1
          class="loan-detail__cont__loans__iframe__title"
          *ngIf="!frameLoaded"
          style="color: #fff; text-align: center"
        >
          請稍候，我們正在創建您的合約
        </h1>
      </div>
      <!-- <iframe [src]="safeUrl" width="100%" height="600px" style="z-index: 9" id="myIframe"></iframe> -->
      <!-- <pdf-viewer [src]="safeUrl?.toString()" [original-size]="false" style="height: 800px"></pdf-viewer> -->
    </div>
    <app-signature [childData]="data" *ngIf="this.frameLoaded"></app-signature>
  </div>
  <app-footer />
</div>
