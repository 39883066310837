import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  registrationForm: FormGroup;
  name = false;
  phoneNumber = false;
  otp = false;
  password = false;
  confirmPassword = false;
  otpSent = false;
  passwordMismatch = false;
  otpVerified = false;
  sendingOtp = false;
  constructor(private fb: FormBuilder) {
    this.registrationForm = this.fb.group({
      name: ['', Validators.required],
      phone_number: ['', [Validators.required, Validators.pattern(/^\d{8}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
      password: ['', Validators.required],
      confirmPassword: ['', [Validators.required]],
    });
  }
  passwordMatchValidator() {
    const passwordCont = this.registrationForm.get('password');
    const confirmPasswordCont = this.registrationForm.get('confirmPassword');
    if (passwordCont && confirmPasswordCont) {
      const password = passwordCont.value;
      const confirmPassword = confirmPasswordCont.value;
      return password === confirmPassword ? null : (this.passwordMismatch = true);
    } else {
      return (this.passwordMismatch = true);
    }
  }
  async onSubmit() {
    if (this.passwordMatchValidator()) {
      return;
    }
    this.name = true;
    this.password = true;
    this.confirmPassword = true;
    if (this.registrationForm.valid) {
      const credentials = {
        name: this.registrationForm.value.name,
        password: this.registrationForm.value.password,
        phone_number: this.registrationForm.get('phone_number')!.value,
      };
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/user-registration/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(credentials),
        });

        if (response.ok) {
          const data = await response.json();
          const user_id = data.user_id;
          const now: any = new Date().getTime();
          localStorage.setItem('token_timestamp', now);
          localStorage.setItem('id_token', data.token.access);
          localStorage.setItem('user_id', user_id);
          window.location.replace('/apply');
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const nameCont = this.registrationForm.get('name');
      const passwordCont = this.registrationForm.get('password');
      const confirmPasswordCont = this.registrationForm.get('confirmPassword');
      if (nameCont) {
        this.name = nameCont.invalid;
      }
      if (passwordCont) {
        this.password = passwordCont.invalid;
      }
      if (confirmPasswordCont) {
        this.phoneNumber = confirmPasswordCont.invalid;
      }
    }
  }
  async verifyOTP() {
    this.otp = true;
    if (
      this.registrationForm.get('name')!.valid &&
      this.registrationForm.get('phone_number')!.valid &&
      this.registrationForm.get('otp')!.valid
    ) {
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/verify-otp/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.registrationForm.get('name')!.value,
            phone_number: this.registrationForm.get('phone_number')!.value,
            otp: this.registrationForm.get('otp')!.value,
          }),
        });

        if (response.ok) {
          // const data = await response.json();
          // this.otpVerified = true;
          const data = await response.json();
          const user_id = data.user_id;
          const now: any = new Date().getTime();
          localStorage.setItem('token_timestamp', now);
          localStorage.setItem('id_token', data.token.access);
          localStorage.setItem('user_id', user_id);
          window.location.replace('/apply');
          console.log('Authentication successful');
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const otpCont = this.registrationForm.get('otp');
      if (otpCont) {
        this.otp = otpCont.invalid;
      }
    }
  }
  async sendOTP() {
    this.name = true;
    this.phoneNumber = true;
    if (this.registrationForm.get('phone_number')!.valid) {
      this.sendingOtp = true;
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/send-otp/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.registrationForm.get('name')!.value,
            phone_number: this.registrationForm.get('phone_number')!.value,
          }),
        });

        if (response.ok) {
          // const data = await response.json();
          this.sendingOtp = false;
          this.otpSent = true;
          console.log('Authentication successful');
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const usernameCont = this.registrationForm.get('phone_number');
      const nameCont = this.registrationForm.get('name');

      if (usernameCont) {
        this.phoneNumber = usernameCont.invalid;
      }
      if (nameCont) {
        this.name = nameCont.invalid;
      }
    }
  }
  resetError() {
    this.name = false;
    this.phoneNumber = false;
    this.password = false;
    this.confirmPassword = false;
    this.otp = false;
    this.passwordMismatch = false;
  }
}
