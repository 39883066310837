import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-personal-loan',
  templateUrl: './personal-loan.component.html',
  styleUrls: ['./personal-loan.component.scss'],
})
export class PersonalLoanComponent {
  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {
    this.titleService.setTitle('網上即批低息私人貸款 | 免TU審查 | Credit Pro');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Credit Pro提供網上即批低息私人貸款服務，並且免去信貸報告（TU）的審查過程。我們擁有快速、便捷的網上申請流程，讓您輕鬆獲得所需現金。無論是應對緊急資金需求還是實現個人目標，我們專業的團隊將根據您的情況提供最適合的低息貸款方案。立即體驗Credit Pro的網上即批低息私人貸款服務，輕鬆解決財務需求，應急錢，應急現金無難度。',
    });

    // Set Open Graph tags
    this.metaService.updateTag({ property: 'og:title', content: '網上即批低息私人貸款 | 免TU審查 | Credit Pro' });
    this.metaService.updateTag({
      property: 'og:description',
      content:
        'Credit Pro提供網上即批低息私人貸款服務，並且免去信貸報告（TU）的審查過程。我們擁有快速、便捷的網上申請流程，讓您輕鬆獲得所需現金。無論是應對緊急資金需求還是實現個人目標，我們專業的團隊將根據您的情況提供最適合的低息貸款方案。立即體驗Credit Pro的網上即批低息私人貸款服務，輕鬆解決財務需求，應急錢，應急現金無難度。',
    });
    this.metaService.updateTag({ property: 'og:url', content: 'https://creditprohk.com/personal-loan' });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/footer-logo.png',
    });

    // Set canonical link
    var canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      (canonicalLink as HTMLLinkElement).href = 'https://creditprohk.com/personal-loan';
    } else {
      var newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      newCanonicalLink.href = 'https://creditprohk.com/personal-loan';

      document.head.appendChild(newCanonicalLink);
    }
  }
}
