import { Component } from '@angular/core';

@Component({
  selector: 'app-img-text-reverse',
  templateUrl: './img-text-reverse.component.html',
  styleUrls: ['./img-text-reverse.component.scss']
})
export class ImgTextReverseComponent {

}
