<div class="info container">
  <div class="info__left">
    <img
      src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/logo-v.svg"
      alt="credit pro"
      width="255"
      height="224"
    />
  </div>
  <div class="info__right">
    <h2 class="info__right__title">CREDIT PRO FINANCE LIMITED</h2>
    <p>
      無論小額借貸還是大額借貸, 我們均能提供極速免TU網上貸款服務, 盡低息貸款、盡快批核過程、盡高私人借貸額,
      只需提交所需借錢文件, 不論申請貸款的理由, 我們都能提供簡易靈活的貸款服務, 滿足你的周轉需要！
    </p>
    <div class="info__right__cont">
      <div class="info__right__cont__row">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30" fill="none">
          <path
            d="M23.1668 24.5484C23.1668 26.8461 21.3029 28.7112 19.0045 28.7112H5.68185C3.38313 28.7112 1.51953 26.8469 1.51953 24.5484V11.2266C1.51953 8.92839 3.38339 7.06348 5.68185 7.06348H19.0045C21.3032 7.06348 23.1668 8.92708 23.1668 11.2266V24.5484Z"
            fill="white"
          />
          <path
            d="M19.0047 29.4544H5.68206C2.97809 29.4544 0.777344 27.2542 0.777344 24.5486V11.2268C0.777344 8.52203 2.97783 6.32129 5.68206 6.32129H19.0047C21.7092 6.32129 23.9094 8.52229 23.9094 11.2268V24.5494C23.9094 27.2542 21.7092 29.4544 19.0047 29.4544ZM5.68206 7.80635C3.7962 7.80635 2.26215 9.34093 2.26215 11.2265V24.5486C2.26215 26.435 3.79646 27.9685 5.68206 27.9685H19.0047C20.8905 27.9685 22.4246 26.435 22.4246 24.5486V11.2268C22.4246 9.34197 20.8903 7.80661 19.0047 7.80661L5.68206 7.80635Z"
            fill="#00A79D"
          />
          <path
            d="M30.3924 0.545898L29.1195 1.19294C22.4623 4.57645 15.0758 12.4138 11.0368 19.0415C10.6423 18.3051 10.5252 18.0638 10.1553 17.2507C8.58197 14.4244 6.44986 11.8972 5.40778 10.9296L3.51562 11.448L3.55911 11.5617C4.62137 13.079 6.40481 16.3708 8.81774 23.428C8.98147 23.7395 9.15803 24.0497 9.36838 24.3418C9.92034 25.1117 10.559 25.3424 10.9975 25.4003C10.9981 25.4003 10.998 25.4003 10.998 25.4003C12.2494 25.563 13.1158 24.6713 13.7738 23.5815C18.6424 11.8336 27.4904 4.46118 31.2194 2.02651L30.3924 0.545898Z"
            fill="#154854"
          />
        </svg>
        <p>申請不限TU, 任何信貸評級一樣幫到你</p>
      </div>
      <div class="info__right__cont__row">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30" fill="none">
          <path
            d="M23.1668 24.5484C23.1668 26.8461 21.3029 28.7112 19.0045 28.7112H5.68185C3.38313 28.7112 1.51953 26.8469 1.51953 24.5484V11.2266C1.51953 8.92839 3.38339 7.06348 5.68185 7.06348H19.0045C21.3032 7.06348 23.1668 8.92708 23.1668 11.2266V24.5484Z"
            fill="white"
          />
          <path
            d="M19.0047 29.4544H5.68206C2.97809 29.4544 0.777344 27.2542 0.777344 24.5486V11.2268C0.777344 8.52203 2.97783 6.32129 5.68206 6.32129H19.0047C21.7092 6.32129 23.9094 8.52229 23.9094 11.2268V24.5494C23.9094 27.2542 21.7092 29.4544 19.0047 29.4544ZM5.68206 7.80635C3.7962 7.80635 2.26215 9.34093 2.26215 11.2265V24.5486C2.26215 26.435 3.79646 27.9685 5.68206 27.9685H19.0047C20.8905 27.9685 22.4246 26.435 22.4246 24.5486V11.2268C22.4246 9.34197 20.8903 7.80661 19.0047 7.80661L5.68206 7.80635Z"
            fill="#00A79D"
          />
          <path
            d="M30.3924 0.545898L29.1195 1.19294C22.4623 4.57645 15.0758 12.4138 11.0368 19.0415C10.6423 18.3051 10.5252 18.0638 10.1553 17.2507C8.58197 14.4244 6.44986 11.8972 5.40778 10.9296L3.51562 11.448L3.55911 11.5617C4.62137 13.079 6.40481 16.3708 8.81774 23.428C8.98147 23.7395 9.15803 24.0497 9.36838 24.3418C9.92034 25.1117 10.559 25.3424 10.9975 25.4003C10.9981 25.4003 10.998 25.4003 10.998 25.4003C12.2494 25.563 13.1158 24.6713 13.7738 23.5815C18.6424 11.8336 27.4904 4.46118 31.2194 2.02651L30.3924 0.545898Z"
            fill="#154854"
          />
        </svg>
        <p>整個貸款申請過程可於網上完成, 過程更流暢簡單</p>
      </div>
      <div class="info__right__cont__row">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30" fill="none">
          <path
            d="M23.1668 24.5484C23.1668 26.8461 21.3029 28.7112 19.0045 28.7112H5.68185C3.38313 28.7112 1.51953 26.8469 1.51953 24.5484V11.2266C1.51953 8.92839 3.38339 7.06348 5.68185 7.06348H19.0045C21.3032 7.06348 23.1668 8.92708 23.1668 11.2266V24.5484Z"
            fill="white"
          />
          <path
            d="M19.0047 29.4544H5.68206C2.97809 29.4544 0.777344 27.2542 0.777344 24.5486V11.2268C0.777344 8.52203 2.97783 6.32129 5.68206 6.32129H19.0047C21.7092 6.32129 23.9094 8.52229 23.9094 11.2268V24.5494C23.9094 27.2542 21.7092 29.4544 19.0047 29.4544ZM5.68206 7.80635C3.7962 7.80635 2.26215 9.34093 2.26215 11.2265V24.5486C2.26215 26.435 3.79646 27.9685 5.68206 27.9685H19.0047C20.8905 27.9685 22.4246 26.435 22.4246 24.5486V11.2268C22.4246 9.34197 20.8903 7.80661 19.0047 7.80661L5.68206 7.80635Z"
            fill="#00A79D"
          />
          <path
            d="M30.3924 0.545898L29.1195 1.19294C22.4623 4.57645 15.0758 12.4138 11.0368 19.0415C10.6423 18.3051 10.5252 18.0638 10.1553 17.2507C8.58197 14.4244 6.44986 11.8972 5.40778 10.9296L3.51562 11.448L3.55911 11.5617C4.62137 13.079 6.40481 16.3708 8.81774 23.428C8.98147 23.7395 9.15803 24.0497 9.36838 24.3418C9.92034 25.1117 10.559 25.3424 10.9975 25.4003C10.9981 25.4003 10.998 25.4003 10.998 25.4003C12.2494 25.563 13.1158 24.6713 13.7738 23.5815C18.6424 11.8336 27.4904 4.46118 31.2194 2.02651L30.3924 0.545898Z"
            fill="#154854"
          />
        </svg>
        <p>因應客戶不同評級去設定不同貸款計劃, 較低的利息或較長的還款期、助你還款變得彈性 靈活減輕債務負擔</p>
      </div>
    </div>
  </div>
</div>
