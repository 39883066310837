<div class="imgTextMortgage">
  <img
    src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/down-arrow.png"
    alt="down arrow"
    width="48"
    height="84"
    class="imgTextMortgage__downArrow"
  />
  <div class="imgTextMortgage__cont container">
    <div class="imgTextMortgage__cont__left">
      <h1 class="imgTextMortgage__cont__left__title">樓宇按揭</h1>
      <p>
        [CREDIT PRO]致力於為客戶提供個性化、靈活的抵押貸款, 無需查看客戶的信用評級報告 (TU)。
        無需擔心申請貸款對其信用評級的影響, 讓客戶可以節省更多時間, 使周轉更靈活、更快捷 同時, 為滿足不同客戶的需求,
        【CREDIT PRO】提供多種貸款方案和多種按揭方式, 審批速度快 安排喺同一天提取貸款。
      </p>
    </div>
    <div class="imgTextMortgage__cont__img">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/phone-money.png"
        alt="phone money"
        width="306"
        height="244"
      />
    </div>
  </div>
</div>
