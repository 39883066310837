<div class="account">
  <app-header />
  <div class="account__cont container">
    <div class="account__cont__row" *ngIf="userData !== undefined && userData.length > 0">
      <div class="account__cont__row__left">
        <h5>{{ userData[0].chinese_name }}, 歡迎申請我們的貸款</h5>
        <p>{{ userData[0].phone_number }}</p>
      </div>
      <div class="account__cont__row__right">
        <a class="account__cont__row__right__apply" href="/apply">立即申請</a>
        <a class="account__cont__row__right__logout" (click)="logout()">登出</a>
      </div>
    </div>
    <div
      class="account__cont__row__right__noApplication"
      *ngIf="
        remainingLoanData !== undefined &&
        remainingLoanData.length == 0 &&
        completedLoanData !== undefined &&
        completedLoanData.length == 0
      "
    >
      <h2>還在猶豫？即刻點擊下方立即申請 <br />極速批核 | 免查TU | 全程網上辦妥</h2>
      <a class="account__cont__row__right__apply" href="/apply">立即申請</a>
    </div>
    <div class="account__cont__loans" *ngIf="remainingLoanData !== undefined && remainingLoanData.length > 0">
      <h2 class="account__cont__loans__title">我的貸款申請</h2>
      <div class="account__cont__loans__cont" *ngFor="let application of remainingLoanData">
        <app-account-detail [childData]="application" [hideBtn]="false" />
      </div>
    </div>
    <div class="account__cont__loans" *ngIf="completedLoanData !== undefined && completedLoanData.length > 0">
      <h2 class="account__cont__loans__title">已完成貸款</h2>
      <div class="account__cont__loans__cont" *ngFor="let application of completedLoanData">
        <app-account-detail [childData]="application" [hideBtn]="false" />
      </div>
    </div>
  </div>
  <app-footer />
</div>
