import { Component, ElementRef, ViewChild, AfterViewInit, Input } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements AfterViewInit {
  @Input() childData: any;
  @ViewChild('signaturePad', { static: true }) signaturePadElement!: ElementRef;

  private signaturePad!: SignaturePad;
  sendData: any;

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  async saveSignature() {
    const formData = new FormData();
    const signatureData = this.signaturePad.toDataURL('image/png');
    formData.append('signature', signatureData);

    // Append other data from childData
    formData.append('contract_file', this.childData.contract_file);
    formData.append('id', this.childData.loan_management);
    formData.append('contract_id', this.childData.id);
    // console.log(signatureData, this.childData);
    // this.sendData = {
    //   signature: signatureData,
    //   contract_file: this.childData.contract_file,
    // };
    // console.log(this.sendData);
    try {
      const response = await fetch(`https://backend.creditprohk.com/en/api/user-contracts/${this.childData.id}/`, {
        method: 'PATCH',
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
        },
        body: formData,
      });

      if (response.ok) {
        // const data = await response.json();
        // this.currentStep++;
        window.location.replace('/account');
      } else {
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
