<div class="account-detail">
  <div class="account-detail__cont">
    <div class="account-detail__cont__top">
      <div class="account-detail__cont__top__row">
        <div class="account-detail__cont__top__row__left">
          <h5>申請日期</h5>
          <p>{{ childData.date.substr(0, 10) }}</p>
        </div>
        <div class="account-detail__cont__top__row__left">
          <h5>申請類型</h5>
          <p>私人貸款</p>
        </div>
        <div class="account-detail__cont__top__row__left">
          <h5>申請狀態</h5>
          <div [ngSwitch]="childData.application_status">
            <p *ngSwitchCase="'1'" class="yellow">跟進審批中</p>
            <p *ngSwitchCase="'2'" class="blue">跟進審批中</p>
            <p *ngSwitchCase="'3'" class="green">等待簽約</p>
            <p *ngSwitchCase="'4'" class="green">批核</p>
            <p *ngSwitchCase="'5'" class="red">申請失敗</p>
            <p *ngSwitchCase="'6'" class="red">取消</p>
            <p *ngSwitchCase="'7'" class="red">取消</p>
            <p *ngSwitchCase="'8'" class="red">取消</p>
            <p *ngSwitchCase="'9'" class="red">取消</p>
            <p *ngSwitchCase="'10'" class="red">申請失敗</p>
            <p *ngSwitchDefault class="yellow">Following Up</p>
          </div>
        </div>
        <div class="account-detail__cont__top__row__left">
          <h5>申請編號</h5>
          <p>{{ childData.id }}</p>
        </div>
        <div class="account-detail__cont__top__row__left">
          <h5>貸款金額</h5>
          <p>HK${{ childData.loan_amount }}</p>
        </div>
        <div class="account-detail__cont__top__row__left" *ngIf="childData.application_status !== '4'">
          <h5>還款期</h5>
          <p>{{ childData.loan_tenor }} 個月</p>
        </div>
      </div>
    </div>
    <div class="account-detail__cont__right" *ngIf="hideBtn === false">
      <a
        class="account-detail__cont__right__withdrawalBtn"
        (click)="showWithdrawal()"
        *ngIf="childData.application_status < '3'"
        >取消申請</a
      >
      <a
        class="account-detail__cont__right__contractBtn"
        [routerLink]="['/account', childData.id]"
        *ngIf="childData.application_status === '3'"
        >簽署合約</a
      >
    </div>
  </div>
</div>
<div *ngIf="WithdrawalConfirm" class="withdrawalPopup">
  <div class="withdrawalPopup__cont">
    <p>是否確認取消申請？</p>
    <div class="withdrawalPopup__cont__btn">
      <a class="withdrawalPopup__cont__btn__withdrawal" (click)="Withdrawal(childData.id)">是</a>
      <a class="withdrawalPopup__cont__btn__cancel" (click)="hideWithdrawal()">否 </a>
    </div>
  </div>
</div>
