import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { TokenService } from '../../token.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent {
  userData: any;
  completedLoanData: any;
  remainingLoanData: any;
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private readonly metaService: Meta,
  ) {
    var canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      (canonicalLink as HTMLLinkElement).href = 'https://creditprohk.com/account';
    } else {
      var newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      newCanonicalLink.href = 'https://creditprohk.com/account';

      document.head.appendChild(newCanonicalLink);
    }
  }
  async ngOnInit() {
    if (this.tokenService.isTokenExpired()) {
      // Token has expired
      localStorage.removeItem('user_id');
      localStorage.removeItem('id_token');
      localStorage.removeItem('token_timestamp');
      this.router.navigate(['/login']);
    } else {
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/user-model/', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          },
        });

        if (response.ok) {
          this.userData = await response.json();
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/loan-management/', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          },
        });

        if (response.ok) {
          let loanData = await response.json();
          this.completedLoanData = loanData.filter(
            (status: { application_status: number }) => status.application_status > 3,
          );
          this.remainingLoanData = loanData.filter(
            (status: { application_status: number }) => status.application_status <= 3,
          );
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }
  logout() {
    localStorage.removeItem('user_id');
    localStorage.removeItem('id_token');
    localStorage.removeItem('token_timestamp');
    window.location.replace('/');
  }
}
