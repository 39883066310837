<div class="credit">
  <div class="credit__cont container">
    <div class="credit__cont__title">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/credit-title.png"
        alt="arrow"
        height="208"
        width="368"
      />
      <p>24小時網上自助申請</p>
    </div>
    <div class="credit__cont__body">
      <div class="credit__cont__body__left">
        <p class="credit__cont__body__left__title">A.I.級輕便</p>
        <p>網上貸款簡化申請</p>
        <p class="credit__cont__body__left__title">A.I.級速度</p>
        <p>網上貸款即時處理</p>
        <p class="credit__cont__body__left__title">A.I.級貸款無需露面</p>
        <p>申請至簽約過數全程網上辦妥</p>
      </div>
      <div class="credit__cont__body__right">
        <div class="credit__cont__body__right__step">
          <div class="credit__cont__body__right__step__box">
            <h2 class="credit__cont__body__right__step__box__title">打破傳統限制</h2>
            <p>網上貸款簡化申請</p>
          </div>
          <div class="credit__cont__body__right__step__box">
            <h2 class="credit__cont__body__right__step__box__title">申請至過數 最快15分鐘</h2>
            <p>即時滿足您的緊急周轉需要</p>
          </div>
          <div class="credit__cont__body__right__step__box">
            <h2 class="credit__cont__body__right__step__box__title">網上貸零露面 零尷尬</h2>
            <p>24小時網上服務 不受時間地區限制</p>
          </div>
        </div>
        <img
          src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/graph-4.svg"
          alt="graphic-1"
          width="78"
          height="510"
        />
      </div>
    </div>
  </div>
</div>
