import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, provideHttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoanProcessComponent } from './components/loan-process/loan-process.component';
import { CreditComponent } from './components/credit/credit.component';
import { OnlineProcessComponent } from './components/online-process/online-process.component';
import { BenefitsComponent } from './components/benefits/benefits.component';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { QaComponent } from './components/qa/qa.component';
import { DebtComponent } from './pages/debt/debt.component';
import { FaqComponent } from './pages/faq/faq.component';
import { PersonalLoanComponent } from './pages/personal-loan/personal-loan.component';
import { ImgTextComponent } from './components/img-text/img-text.component';
import { ImgTextReverseComponent } from './components/img-text-reverse/img-text-reverse.component';
import { FormComponent } from './components/form/form.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ApplyComponent } from './pages/apply/apply.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { RecentBlogComponent } from './components/recent-blog/recent-blog.component';
import { AccountComponent } from './pages/account/account.component';
import { AccountDetailComponent } from './components/account-detail/account-detail.component';
import { LoanDetailComponent } from './pages/loan-detail/loan-detail.component';
import { SignatureComponent } from './components/signature/signature.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { MortgageComponent } from './pages/mortgage/mortgage.component';
import { ImgTextMortgageComponent } from './components/img-text-mortgage/img-text-mortgage.component';
import { MortgageBenefitsComponent } from './components/mortgage-benefits/mortgage-benefits.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { provideAnimations } from '@angular/platform-browser/animations';
import { VERSION as CDK_VERSION } from '@angular/cdk';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  VERSION as MAT_VERSION,
  MatNativeDateModule,
} from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { MoneyLendersComponent } from './pages/money-lenders/money-lenders.component';
import { PersonalLoanQaComponent } from './components/personal-loan-qa/personal-loan-qa.component';
import { CalculatorComponent } from './pages/calculator/calculator.component';
import { FormsModule } from '@angular/forms';
import { StickySocialComponent } from './components/sticky-social/sticky-social.component';
@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoanProcessComponent,
    CreditComponent,
    OnlineProcessComponent,
    BenefitsComponent,
    CompanyInfoComponent,
    QaComponent,
    DebtComponent,
    FaqComponent,
    PersonalLoanComponent,
    ImgTextComponent,
    ImgTextReverseComponent,
    FormComponent,
    LoginComponent,
    RegistrationComponent,
    ApplyComponent,
    BlogComponent,
    BlogDetailComponent,
    RecentBlogComponent,
    AccountComponent,
    AccountDetailComponent,
    LoanDetailComponent,
    SignatureComponent,
    ForgotPasswordComponent,
    MortgageComponent,
    ImgTextMortgageComponent,
    MortgageBenefitsComponent,
    PrivacyPolicyComponent,
    DisclaimerComponent,
    MoneyLendersComponent,
    PersonalLoanQaComponent,
    CalculatorComponent,
    StickySocialComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
  ],
  providers: [
    provideAnimations(),
    provideHttpClient(),
    importProvidersFrom(MatNativeDateModule),
    { provide: MAT_DATE_LOCALE, useValue: 'zh-HK' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
