<div class="loanProcess">
  <div class="loanProcess__cont container">
    <div class="loanProcess__cont__left">
      <p>極速處理，即批即過戶</p>
      <h2>全自動簡化貸款過程貸款申請無間斷</h2>
      <p>為你提供最合理合適報價</p>
    </div>
    <a href="" class="loanProcess__cont__apply" href="/apply">立即申請</a>
  </div>
</div>
