import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotpasswordForm: FormGroup;
  phoneNumber = false;
  otp = false;
  password = false;
  confirmPassword = false;
  otpSent = false;
  otpVerified = false;
  constructor(private fb: FormBuilder) {
    this.forgotpasswordForm = this.fb.group({
      phone_number: ['', [Validators.required, Validators.pattern(/^\d{8}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
      password: ['', Validators.required],
      confirmPassword: ['', [Validators.required]],
    });
  }
  async onReset() {
    this.password = true;
    this.confirmPassword = true;
    if (this.forgotpasswordForm.valid) {
      const credentials = this.forgotpasswordForm.value;
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/user-registration/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(credentials),
        });

        if (response.ok) {
          window.location.replace('/login');
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const passwordCont = this.forgotpasswordForm.get('password');
      const confirmPasswordCont = this.forgotpasswordForm.get('confirmPassword');
      if (passwordCont) {
        this.password = passwordCont.invalid;
      }
      if (confirmPasswordCont) {
        this.phoneNumber = confirmPasswordCont.invalid;
      }
    }
  }
  async verifyOTP() {
    this.otp = true;
    if (this.forgotpasswordForm.get('phone_number')!.valid && this.forgotpasswordForm.get('otp')!.valid) {
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/verify-otp/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            phone_number: this.forgotpasswordForm.get('phone_number')!.value,
            otp: this.forgotpasswordForm.get('otp')!.value,
          }),
        });

        if (response.ok) {
          // const data = await response.json();
          this.otpVerified = true;
          console.log('Authentication successful');
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const otpCont = this.forgotpasswordForm.get('otp');
      if (otpCont) {
        this.otp = otpCont.invalid;
      }
    }
  }
  async sendOTP() {
    this.phoneNumber = true;
    if (this.forgotpasswordForm.get('phone_number')!.valid) {
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/send-otp/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            phone_number: this.forgotpasswordForm.get('phone_number')!.value,
            otp: this.forgotpasswordForm.get('otp')!.value,
          }),
        });

        if (response.ok) {
          // const data = await response.json();
          this.otpSent = true;
          console.log('Authentication successful');
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const usernameCont = this.forgotpasswordForm.get('phone_number');
      if (usernameCont) {
        this.phoneNumber = usernameCont.invalid;
      }
    }
  }
  resetError() {
    this.phoneNumber = false;
    this.password = false;
    this.confirmPassword = false;
    this.otp = false;
  }
}
