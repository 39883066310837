import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss'],
})
export class AccountDetailComponent {
  @Input() childData: any;
  @Input() hideBtn: any;
  WithdrawalConfirm = false;
  async Withdrawal(id: number) {
    const data = { is_withdrawal: true };
    try {
      const response = await fetch(`https://backend.creditprohk.com/en/api/loan-management/${id}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        window.location.reload();
      } else {
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  showWithdrawal() {
    this.WithdrawalConfirm = true;
  }
  hideWithdrawal() {
    this.WithdrawalConfirm = false;
  }
}
