<div class="blog">
  <app-header />
  <div class="blog__cont container">
    <h2 class="blog__cont__title">探索我們的銀行博客</h2>
    <app-recent-blog [blogData]="recentBlogData" />
    <div class="blog__cont__all">
      <div class="blog__cont__all__top">
        <div class="blog__cont__all__title">All Blogs</div>
        <div class="blog__cont__all__list">
          <a [routerLink]="['/blog', blog.alias]" *ngFor="let blog of blogData" class="blog__box">
            <img [src]="blog.thumbnail_image" alt="blog image" />
            <div class="blog__box__detail">
              <div class="blog__box__detail__top">
                <div class="blog__box__detail__top__author">
                  {{ blog.author_name }} • {{ blog.created.substr(0, 10) }}
                </div>
                <div class="blog__box__detail__top__row">
                  <h3 class="blog__box__detail__top__row__title">{{ blog.title }}</h3>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path
                      d="M7.66699 17L17.667 7M17.667 7H7.66699M17.667 7V17"
                      stroke="#FDFDFD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p>{{ blog.discription }}</p>
              </div>
              <div class="blog__box__detail__bottom">
                <p class="blog__box__detail__bottom__tag blog__box__detail__bottom__tag___green">
                  {{ blog.blog_tag1 }}
                </p>
                <p class="blog__box__detail__bottom__tag blog__box__detail__bottom__tag___yellow">
                  {{ blog.blog_tag2 }}
                </p>
                <p class="blog__box__detail__bottom__tag blog__box__detail__bottom__tag___red">{{ blog.blog_tag3 }}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!-- <div class="blog__cont__all__pagination">
        <a href="" class="blog__cont__all__pagination__prev"
          ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.8337 10.0003H4.16699M4.16699 10.0003L10.0003 15.8337M4.16699 10.0003L10.0003 4.16699"
              stroke="#EFFCFD"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Previous</span>
        </a>
        <p class="blog__cont__all__pagination__page">Page 1 of 10</p>
        <div class="blog__cont__all__pagination__number">
          <a href="" class="blog__cont__all__pagination__number__active">1</a>
          <a href="">2</a>
          <a href="">3</a>
          <a href="">...</a>
          <a href="">8</a>
          <a href="">9</a>
          <a href="">10</a>
        </div>
        <a href="" class="blog__cont__all__pagination__next">
          <span>Next</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.16699 10.0003H15.8337M15.8337 10.0003L10.0003 4.16699M15.8337 10.0003L10.0003 15.8337"
              stroke="#EFFCFD"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div> -->
    </div>
  </div>
  <app-footer />
</div>
