import { Component } from '@angular/core';

@Component({
  selector: 'app-money-lenders',
  templateUrl: './money-lenders.component.html',
  styleUrls: ['./money-lenders.component.scss']
})
export class MoneyLendersComponent {

}
