<div class="footer">
  <div class="footer__cont container">
    <div class="footer__cont__top">
      <div class="footer__cont__top__cont">
        <h1>“忠告：借錢梗要還，咪俾錢中介”</h1>
        <a href="/"
          ><img
            src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/footer-logo.png"
            alt="credit pro"
            width="98"
            height="86"
        /></a>
        <div class="footer__cont__top__text">
          <h5>放債人牌照號碼：1227/2023</h5>
          <p>
            Room 11, 12/F, Tower 3, China Hong Kong City, Tsim Sha Tsui<br />
            地址：尖沙咀中港城 3座 12樓11室 | 辦公時間：星期一至星期日 - 10:00-18:00 <br />
            客戶服務／投訴熱線：2153 1523 | WhatsApp：5493 6882  | 電子郵件：CreditPro2023@gmail.com
          </p>
          <p>&nbsp;</p>
          <p>
            備注：Credit Pro Finance 還款期為6至60個月之間的私人貸款。實際年利率介乎6-46%，將視乎個別情況而定。<br />
            參考例子： 貸款額HK$10,000 12個月之分期貸款，每月還款額為HK$965實際年利率：28%總還款額HK$11,580
          </p>
        </div>
      </div>

      <div class="footer__cont__top__links">
        <a href="/apply">網上申請</a>
        <a href="/faq">常見問題</a>
        <a href="/debt">債務一筆清</a>
        <a href="/privacyPolicy">私隱條例</a>
        <a href="/disclaimer">免責聲明</a>
        <a href="/moneyLenders">放債人條例</a>
      </div>
    </div>
    <div class="footer__cont__bottom">
      <p>© 2023 CreditPro. All rights reserved.</p>
      <!-- <div class="footer__cont__bottom__links">
        <a>Privacy Policy</a>
        <a>Terms of Service</a>
        <a>Cookies Settings</a>
      </div> -->
    </div>
  </div>
</div>
