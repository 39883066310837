import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-personal-loan-qa',
  templateUrl: './personal-loan-qa.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./personal-loan-qa.component.scss'],
})
export class PersonalLoanQaComponent {
  faqs: FAQItem[] = [
    {
      question: ' Creditpro 貸款批核流程如何運作？',
      showAnswer: true,
      answerHtml:
        '<p>A.I. 級簡便申請，極速處理，網上貸款即批即過數* </p><p>1.透過creditpro網上申請貸款，首先利用綁定的手機收到驗證碼。</p><p>2.填寫個人資料，上載身份證以作記錄。</p><p>3.填妥及核對資料後，網上貸款即批，最快1秒!就可收到審批結果(會提供私人貸款年利率及貸款還款期) 。</p><p>4.身份及合約一經確認，網上貸款即時過數*，以「轉數快」過戶到已核實的放款銀行戶口。</p>',
      safeAnswer: '',
    },
    {
      question: ' 想申請貸款，必須符合甚麼資格？',
      showAnswer: false,
      answerHtml: '<p>您必須年滿18歲，並擁有香港居留權之居民，自僱或受僱人士均可。</p>',
      safeAnswer: '',
    },
    {
      question: '當申請貸款時，CPF會否向信貸資料庫查閱申請人於銀行及其他財務機構之個人信貸資料？',
      showAnswer: false,
      answerHtml: '<p>我們是不查和不影響每客戶的信貸報告及評級的</p>',
      safeAnswer: '',
    },
    {
      question: '申請貸款事宜會否被披露？',
      showAnswer: false,
      answerHtml: '<p>絶對不會。根據個人資料(私隱)條例規定，申請人的資料是絶對保密。</p>',
      safeAnswer: '',
    },
    {
      question: '沒有入息證明文件，可以申請貸款嗎?',
      showAnswer: false,
      answerHtml: '<p>當然可以。我們會按個別申請人之情況，豁免入息證明。</p>',
      safeAnswer: '',
    },
    {
      question: 'Creditpro 收取什麽費用？是否有借貸利息之外的任何隱性收費？',
      showAnswer: false,
      answerHtml:
        '<p>絕不會收取任何申請費、擔保費、 影印費或介紹費等隱性費用，而且手續費全免。所有申請，無論被取消、被拒絕或授予貸款，只要最終無接納貸款，都不會有任何收費，亦絕對不會追討因磋商或授予貸款所引發或相關費用或收費。我們絕對無任何借貸利息之外的隱性收費，所有條款透明度極高。所見即所得，一目了然。</p>',
      safeAnswer: '',
    },
    // Add more FAQ items here
  ];
  constructor(private sanitizer: DomSanitizer) {
    this.faqs.forEach((faq) => {
      faq.safeAnswer = this.sanitizer.bypassSecurityTrustHtml(faq.answerHtml);
    });
  }
  toggleAnswer(faq: FAQItem) {
    this.faqs.forEach((item) => {
      if (item !== faq) {
        item.showAnswer = false; // Close all other FAQ items
      }
    });

    faq.showAnswer = !faq.showAnswer;
  }
}
interface FAQItem {
  question: string;
  // answer: string;
  showAnswer: boolean;
  answerHtml: string;
  safeAnswer: SafeHtml;
}
