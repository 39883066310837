import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {
    this.titleService.setTitle('常見問題 | Credit Pro');
    this.metaService.updateTag({
      name: 'description',
      content: '常見問題 | Credit Pro',
    });

    // Set Open Graph tags
    this.metaService.updateTag({ property: 'og:title', content: '常見問題 | Credit Pro' });
    this.metaService.updateTag({
      property: 'og:description',
      content: '常見問題 | Credit Pro',
    });
    this.metaService.updateTag({ property: 'og:url', content: 'https://creditprohk.com/faq' });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/footer-logo.png',
    });

    // Set canonical link
    var canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      (canonicalLink as HTMLLinkElement).href = 'https://creditprohk.com/faq';
    } else {
      var newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      newCanonicalLink.href = 'https://creditprohk.com/faq';

      document.head.appendChild(newCanonicalLink);
    }
  }
}
