<div class="sticky-social">
  <a href="tel:+85221531523">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#63CCE9" />
      <g clip-path="url(#clip0_873_2158)">
        <path
          d="M19.7297 13.1532C19.3687 12.2813 18.4172 11.8173 17.5078 12.0657L13.3828 13.1907C12.5672 13.4157 12 14.1563 12 15.0001C12 26.597 21.4031 36.0001 33 36.0001C33.8438 36.0001 34.5844 35.4329 34.8094 34.6173L35.9344 30.4923C36.1828 29.5829 35.7187 28.6313 34.8469 28.2704L30.3469 26.3954C29.5828 26.0766 28.6969 26.297 28.1766 26.9391L26.2828 29.2501C22.9828 27.6891 20.3109 25.0173 18.75 21.7173L21.0609 19.8282C21.7031 19.3032 21.9234 18.422 21.6047 17.6579L19.7297 13.1579V13.1532Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_873_2158">
          <rect width="24" height="24" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  </a>
  <a href="https://www.facebook.com/creditprofinance/" target="_blank"
    ><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#337FFF" />
      <path
        d="M29.8717 25.5619L30.5556 21.2166H26.3428V18.3922C26.3428 17.204 26.9309 16.043 28.8117 16.043H30.754V12.3427C29.6229 12.1624 28.4799 12.0648 27.3344 12.0508C23.867 12.0508 21.6033 14.1352 21.6033 17.9034V21.2166H17.7598V25.5619H21.6033V36.0721H26.3428V25.5619H29.8717Z"
        fill="white"
      />
    </svg>
  </a>
  <a href="https://api.whatsapp.com/send?phone=85254936882" target="_blank"
    ><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48.0005" height="48" rx="24" fill="#00D95F" />
      <path
        d="M12 35.1112L13.722 28.7086C12.3332 26.2144 11.892 23.304 12.4793 20.5113C13.0666 17.7186 14.6429 15.231 16.9191 13.505C19.1952 11.7789 22.0185 10.9302 24.8709 11.1146C27.7232 11.299 30.4133 12.5041 32.4475 14.5088C34.4817 16.5135 35.7235 19.1833 35.9451 22.0283C36.1667 24.8732 35.3532 27.7026 33.6539 29.9971C31.9546 32.2916 29.4834 33.8974 26.6939 34.5197C23.9044 35.1421 20.9836 34.7393 18.4675 33.3853L12 35.1112ZM18.7794 30.9894L19.1794 31.2263C21.0018 32.3047 23.1304 32.751 25.2334 32.4955C27.3364 32.2401 29.2957 31.2974 30.806 29.8142C32.3163 28.331 33.2927 26.3907 33.5831 24.2957C33.8735 22.2007 33.4615 20.0686 32.4114 18.2317C31.3613 16.3948 29.732 14.9563 27.7775 14.1404C25.823 13.3245 23.653 13.1769 21.6057 13.7207C19.5584 14.2645 17.7489 15.4692 16.459 17.147C15.1691 18.8247 14.4713 20.8813 14.4745 22.9963C14.4728 24.75 14.9586 26.4697 15.8778 27.9641L16.1286 28.3769L15.166 31.9505L18.7794 30.9894Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.4866 24.6404C28.2521 24.4516 27.9776 24.3187 27.6839 24.2519C27.3902 24.185 27.085 24.186 26.7917 24.2547C26.3511 24.4374 26.0664 25.1277 25.7816 25.4729C25.7216 25.5557 25.6334 25.6137 25.5335 25.6361C25.4336 25.6586 25.329 25.6438 25.2393 25.5947C23.626 24.9639 22.2737 23.8071 21.4021 22.3122C21.3278 22.2189 21.2926 22.1005 21.304 21.9818C21.3154 21.8632 21.3724 21.7535 21.4632 21.676C21.7807 21.3622 22.0139 20.9734 22.1411 20.5458C22.1693 20.074 22.0611 19.6041 21.8292 19.1921C21.65 18.6145 21.3089 18.1002 20.8462 17.7099C20.6076 17.6028 20.343 17.5669 20.0844 17.6065C19.8257 17.6461 19.5841 17.7596 19.3887 17.9333C19.0493 18.2256 18.78 18.5901 18.6005 19.0001C18.421 19.41 18.3359 19.855 18.3514 20.3021C18.3525 20.5532 18.3843 20.8032 18.4463 21.0466C18.6037 21.6313 18.8458 22.1899 19.1649 22.7048C19.3952 23.0992 19.6464 23.481 19.9175 23.8486C20.7985 25.056 21.9059 26.0811 23.1783 26.8671C23.8169 27.2666 24.4993 27.5913 25.2122 27.835C25.9527 28.1701 26.7703 28.2987 27.5782 28.2072C28.0385 28.1377 28.4746 27.9562 28.8481 27.6789C29.2217 27.4015 29.5212 27.0368 29.7204 26.6167C29.8375 26.363 29.873 26.0793 29.8221 25.8046C29.7001 25.2428 28.9476 24.9112 28.4866 24.6404Z"
        fill="white"
      />
    </svg>
  </a>
</div>
