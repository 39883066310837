<div class="calculator">
  <h2>EMI Calculator</h2>

  <form [formGroup]="emiForm">
    <div class="grid grid___same gap___df">
      <div class="input">
        <label for="amount">Loan Amount:</label>
        <input type="text" id="amount" formControlName="amount" />
      </div>
      <div class="input">
        <label for="period">Loan Period (in months):</label>
        <input type="text" id="period" formControlName="period" />
      </div>
    </div>
    <div class="grid grid___same gap___df">
      <div class="input">
        <label for="annualRate">Annual Interest Rate (%):</label>
        <input type="text" id="annualRate" formControlName="annualRate" />
      </div>
      <div class="input">
        <label for="frequency">Payment Frequency:</label>
        <select id="frequency" formControlName="frequency">
          <option value="monthly">Monthly</option>
          <option value="halfMonthly">Half-Monthly</option>
        </select>
      </div>
    </div>
    <button type="button" (click)="calculateEMI()" class="form-submit-btn">Calculate EMI</button>
    <button type="button" (click)="clearForm()" class="red">Clear Form</button>
  </form>

  <div id="result" *ngIf="result !== undefined">{{ result }}</div>
</div>
