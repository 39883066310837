import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-mortgage',
  templateUrl: './mortgage.component.html',
  styleUrls: ['./mortgage.component.scss'],
})
export class MortgageComponent {
  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {
    this.titleService.setTitle('網上即批低息樓宇按揭貸款 | Credit Pro');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Credit Pro提供網上即批低息樓宇按揭貸款服務。我們的專業團隊利用先進科技，為您提供高效便捷的貸款申請流程。無論您是購買房屋、重新貸款還是進行再融資，我們都能根據您的需求提供符合經濟的解決方案。立即使用我們的網上貸款申請服務，享受低息樓宇按揭貸款的便利和效益。',
    });

    // Set Open Graph tags
    this.metaService.updateTag({ property: 'og:title', content: '網上即批低息樓宇按揭貸款 | Credit Pro' });
    this.metaService.updateTag({
      property: 'og:description',
      content:
        'Credit Pro提供網上即批低息樓宇按揭貸款服務。我們的專業團隊利用先進科技，為您提供高效便捷的貸款申請流程。無論您是購買房屋、重新貸款還是進行再融資，我們都能根據您的需求提供符合經濟的解決方案。立即使用我們的網上貸款申請服務，享受低息樓宇按揭貸款的便利和效益。',
    });
    this.metaService.updateTag({ property: 'og:url', content: 'https://creditprohk.com/mortgage' });
    this.metaService.updateTag({
      property: 'og:image',
      content: 'https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/footer-logo.png',
    });

    // Set canonical link
    var canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      (canonicalLink as HTMLLinkElement).href = 'https://creditprohk.com/mortgage';
    } else {
      var newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      newCanonicalLink.href = 'https://creditprohk.com/mortgage';

      document.head.appendChild(newCanonicalLink);
    }
  }
}
