import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogDetailComponent implements OnInit {
  currentURL: string;
  sanitizedHtml!: SafeHtml;
  blogDetailData: any;
  recentBlogData: any;

  constructor(
    private location: Location,
    private sanitizer: DomSanitizer,
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {
    const path = this.location.path();
    const segments = path.split('/');
    this.currentURL = segments[segments.length - 1];
  }
  async ngOnInit() {
    try {
      const response = await fetch('https://backend.creditprohk.com/en/api/blog-detail/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ alias: this.decodeURIComponent(this.currentURL) }),
      });
      if (response.ok) {
        const data = await response.json();
        this.blogDetailData = data;
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(this.blogDetailData.html);
      } else {
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    try {
      const response = await fetch('https://backend.creditprohk.com/en/api/recent-blog/');

      if (response.ok) {
        this.recentBlogData = await response.json();
        this.titleService.setTitle(this.recentBlogData[0].title);
        this.metaService.updateTag({
          name: 'description',
          content: this.recentBlogData[0].discription,
        });

        // Set Open Graph tags
        this.metaService.updateTag({ property: 'og:title', content: this.recentBlogData[0].title });
        this.metaService.updateTag({
          property: 'og:description',
          content: this.recentBlogData[0].discription,
        });
        this.metaService.updateTag({ property: 'og:url', content: `https://creditprohk.com${this.location.path()}` });
        this.metaService.updateTag({
          property: 'og:image',
          content: 'https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/footer-logo.png',
        });

        var canonicalLink = document.querySelector('link[rel="canonical"]');

        if (canonicalLink) {
          (canonicalLink as HTMLLinkElement).href = `https://creditprohk.com${this.location.path()}`;
        } else {
          var newCanonicalLink = document.createElement('link');
          newCanonicalLink.rel = 'canonical';
          newCanonicalLink.href = `https://creditprohk.com${this.location.path()}`;

          document.head.appendChild(newCanonicalLink);
        }
      } else {
        alert('Login Failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  decodeURIComponent(encodedString: string): string {
    return decodeURIComponent(encodedString);
  }
}
