<div class="onlineProcess">
  <div class="onlineProcess__cont container">
    <div class="onlineProcess__cont__title">Online申請 無需現身 全程網上搞掂</div>
    <div class="onlineProcess__cont__process">
      <div class="onlineProcess__cont__process__block">
        <img
          src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/online-step-one.png"
          alt="step 1"
          width="72"
          height="72"
        />
        <p class="onlineProcess__cont__process__block__step">第一步</p>
        <p class="onlineProcess__cont__process__block__title">Online登記</p>
      </div>
      <div class="onlineProcess__cont__process__line"></div>
      <div class="onlineProcess__cont__process__block">
        <img
          src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/online-step-two.png"
          alt="step 2"
          width="72"
          height="72"
        />
        <p class="onlineProcess__cont__process__block__step">第二步</p>
        <p class="onlineProcess__cont__process__block__title">Online簽署</p>
      </div>
      <div class="onlineProcess__cont__process__line"></div>
      <div class="onlineProcess__cont__process__block">
        <img
          src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/online-step-three.png"
          alt="step 3"
          width="72"
          height="72"
        />
        <p class="onlineProcess__cont__process__block__step">第三步</p>
        <p class="onlineProcess__cont__process__block__title">Online過數</p>
      </div>
    </div>
  </div>
</div>
