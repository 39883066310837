import { Component } from '@angular/core';

@Component({
  selector: 'app-img-text-mortgage',
  templateUrl: './img-text-mortgage.component.html',
  styleUrls: ['./img-text-mortgage.component.scss']
})
export class ImgTextMortgageComponent {

}
