<div class="disclaimer">
  <app-header />
  <div class="disclaimer__cont container">
    <h2 class="disclaimer__cont__title">免責聲明</h2>
    <div class="disclaimer__cont__all">
      <div class="disclaimer__cont__all__top">
        <div class="disclaimer__cont__all__title">
          1.
          <p>
            此等網頁中提及之CREDIT PRO FINANCE
            LIMITED及其任何附屬機構的產品及服務，僅提供予當時在法律上合法容許的地區人士。此等網頁並非為身在法律上限制該等產品及服務之資訊傳佈的地區內人士使用，瀏覽此等網頁之人士應了解適用於他們的有關限制。無論在何種情況下，此等網頁均不構成，也不應被視為提議或促請身在法律上禁止該等提議或促請之地區內任何人士，推廣或銷售投資或存款服務。
          </p>
          <br />
          2.
          <p>
            此等網頁中刊載之資訊僅供閣下作為一般資料及參考，並不構成專業或投資建議。本公司已盡力確保此等網頁中所提供之資料的準確性，但並未就此等網頁中所提供之資料的準確性或完整性作出任何明確的或隱含的擔保、保證或聲明，並建議閣下於必要時向閣下之專業顧問諮詢。
          </p>
          <br />
          3.
          <p>
            閣下承認，通過互聯網之通訊並非完全可靠，並且可能出現傳送或運作延誤、數據丟失或被毀。若因取得、使用或依據本網站或其他任何相連網站上所載之任何資訊，或者因向本網站發送或自本網站接收訊息，造成任何直接的、間接的、連帶性的或特殊的損失或損害，本公司概不負責。
          </p>
          <br />
          4.
          <p>
            此等網頁上提供的產品或服務將受制於適用的條款及條件，CREDIT PRO FINANCE
            LIMITED能對自行決定取消或變更該等產品或服務而不作事先通知。本公司亦絕對有權自行決定使用某些產品或服務之客戶資格。
          </p>
          <br />
          5.
          <p>
            此等網頁上提供的產品或服務將受制於適用的條款及條件，CREDIT PRO FINANCE
            LIMITED能對自行決定取消或變更該等產品或服務而不作事先通知。本公司亦絕對有權自行決定使用某些產品或服務之客戶資格。
          </p>
          <br />
          <p>CREDIT PRO FINANCE LIMITED</p>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</div>
