import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  phoneNumber = false;
  otp = false;
  otpSent = false;
  otpVerified = false;
  sendingOtp = false;
  constructor(private fb: FormBuilder) {
    this.loginForm = this.fb.group({
      phone_number: ['', [Validators.required, Validators.pattern(/^\d{8}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
    });
  }
  ngOnInit() {
    const idToken = localStorage.getItem('id_token');
    const userId = localStorage.getItem('user_id');

    if (idToken && userId) {
      window.location.href = '/account'; // Allow access to the route
    }
  }
  async onSubmit() {
    this.phoneNumber = true;
    this.otp = true;
    if (this.loginForm.valid) {
      const credentials = {
        phone_number: this.loginForm.get('phone_number')!.value,
        otp: this.loginForm.get('otp')!.value,
      };
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/verify-otp/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(credentials),
        });

        if (response.ok) {
          const data = await response.json();
          const user_id = data.user_id;
          localStorage.setItem('id_token', data.token.access);
          localStorage.setItem('user_id', user_id);
          const now: any = new Date().getTime();
          localStorage.setItem('token_timestamp', now);
          window.location.replace('/apply');
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const usernameCont = this.loginForm.get('phone_number');
      const otpCont = this.loginForm.get('otp');
      if (usernameCont) {
        this.phoneNumber = usernameCont.invalid;
      }
      if (otpCont) {
        this.otp = otpCont.invalid;
      }
    }
  }
  async sendOTP() {
    this.phoneNumber = true;
    if (this.loginForm.get('phone_number')!.valid) {
      this.sendingOtp = true;
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/send-otp/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            phone_number: this.loginForm.get('phone_number')!.value,
          }),
        });

        if (response.ok) {
          // const data = await response.json();
          this.sendingOtp = false;
          this.otpSent = true;
          console.log('Authentication successful');
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const usernameCont = this.loginForm.get('phone_number');

      if (usernameCont) {
        this.phoneNumber = usernameCont.invalid;
      }
    }
  }
  resetError() {
    this.phoneNumber = false;
    this.otp = false;
  }
}
