<app-header />
<div class="heroVideo">
  <video
    muted
    autoplay
    preload="auto"
    loop
    playsInline
    id="myVideo"
    poster="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/video-poster.jpg"
  >
    <source src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/credit.mp4#t=0" type="video/mp4" />
  </video>
  <div class="heroVideo__cont container">
    <div class="heroVideo__cont__left">
      <div class="heroVideo__cont__left__text">
        <h1 class="heroVideo__cont__left__text__title">極速放款解決您的燃眉之急</h1>
        <p>網上智能貸款 A.I 數據批核，免入息證明，免手續費，為您即時解決資金周轉，輕鬆應你所急！</p>
      </div>
      <div class="heroVideo__cont__left__points">
        <img
          src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/home-point-bg.png"
          alt="background"
          width="588"
          height="
        240"
        />
        <div class="heroVideo__cont__left__points__cont">
          <div class="heroVideo__cont__left__points__cont__row">
            <img
              src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/round-outline.png"
              width="42"
              height="42"
              alt="point"
            />
            <p>免TU 過往信貸不影響申請</p>
          </div>
          <div class="heroVideo__cont__left__points__cont__grid">
            <div class="heroVideo__cont__left__points__cont__row">
              <img
                src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/round-outline.png"
                width="42"
                height="42"
                alt="point"
              />
              <p>24/7即時過數</p>
            </div>
            <div class="heroVideo__cont__left__points__cont__row">
              <img
                src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/round-outline.png"
                width="42"
                height="42"
                alt="point"
              />
              <p>特快網上貸款體驗</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="heroVideo__cont__loan">
      <div class="heroVideo__cont__loan__calculator">
        <div class="heroVideo__cont__loan__calculator__form">
          <div class="heroVideo__cont__loan__calculator__form__row">
            <label for="amount">貸款金額</label>
            <div>
              <p class="heroVideo__cont__loan__calculator__form__row__value">
                {{ loanAmount | currency: 'HKD' : 'symbol' : '1.0-0' }}
              </p>
              <div class="heroVideo__cont__loan__calculator__form__row__slider">
                <img
                  src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/range-input-top.svg"
                  alt="top deco"
                  class="heroVideo__cont__loan__calculator__form__row__slider__top"
                />
                <input
                  type="range"
                  id="amount"
                  name="amount"
                  min="5000"
                  value="10000"
                  max="800000"
                  class="range-slider"
                  (input)="onLoanAmountChange($event)"
                />
                <img
                  src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/range-input-bottom.svg"
                  alt="bottom deco"
                  class="heroVideo__cont__loan__calculator__form__row__slider__bottom"
                />
              </div>
            </div>
          </div>
          <div class="heroVideo__cont__loan__calculator__form__row">
            <label for="month">還款期數</label>
            <div>
              <p class="heroVideo__cont__loan__calculator__form__row__value">{{ loanTennure }}</p>
              <div class="heroVideo__cont__loan__calculator__form__row__slider">
                <img
                  src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/range-input-top.svg"
                  alt="top deco"
                  class="heroVideo__cont__loan__calculator__form__row__slider__top"
                />
                <input
                  type="range"
                  id="month"
                  name="month"
                  min="1"
                  max="60"
                  value="6"
                  class="range-slider"
                  (input)="onLoanTennureChange($event)"
                />
                <img
                  src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/range-input-bottom.svg"
                  alt="bottom deco"
                  class="heroVideo__cont__loan__calculator__form__row__slider__bottom"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="heroVideo__cont__loan__calculator__result">
          <p>每月還款</p>
          <h2>{{ minMonthlyInstallment | currency: 'HKD' : 'symbol' : '1.0-0' }}</h2>
          <div class="heroVideo__cont__loan__calculator__result__divider"></div>
          <h2>{{ maxMonthlyInstallment | currency: 'HKD' : 'symbol' : '1.0-0' }}</h2>
        </div>
      </div>
      <div class="heroVideo__cont__loan__footer">
        <div class="heroVideo__cont__loan__footer__points">
          <p>1. 貸款金額應在$5,000-$800,000之間</p>
          <p>2. 以上計算假設實際年利率為10% - 28%不等， 僅作參考</p>
          <p>3. 客戶可獲享之利率將按其個人信貸狀況而定，實際利率不超過年息48%，還款期一般為3個月至60個月</p>
          <p>4. CREDIT PRO FINANCE LIMITED 擁有對貸款審批及任何爭議之最終決定權</p>
        </div>
        <a class="heroVideo__cont__loan__footer__apply" href="/apply">立即申請</a>
      </div>
    </div>
  </div>
</div>
<app-credit />
<app-loan-process></app-loan-process>
<app-online-process />
<app-benefits />
<div class="company-info">
  <app-company-info />
  <app-personal-loan-qa />
</div>
<app-footer />
<app-sticky-social />
