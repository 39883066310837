<div class="imgText">
  <img
    src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/down-arrow.png"
    alt="down arrow"
    width="48"
    height="84"
    class="imgText__downArrow"
  />
  <div class="imgText__cont container">
    <div class="imgText__cont__left">
      <h1 class="imgText__cont__left__title">私人貸款</h1>
      <p>
        【CREDIT PRO】致力為客戶提供貼身靈活的私人貸款, 毋須查閱客戶的信貸評級報告(TU),
        客戶更不用擔憂申請貸款會影響其信貸評級, 以方便客戶節省更多時間, 令週轉更靈活快捷。同時為滿足不同客戶的需要,
        【CREDIT PRO】提供多項貸款計劃, 及多項申請貸款方法, 批核快捷, 即日安排提取貸款。
      </p>
    </div>
    <div class="imgText__cont__img">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/phone-money.png"
        alt="phone money"
        width="306"
        height="244"
      />
    </div>
  </div>
</div>
