<div class="login">
  <app-header />
  <div class="login__cont container">
    <app-form>
      <div class="login__form">
        <div class="login__form__header">
          <h2 class="login__form__title">歡迎</h2>
          <p class="login__form__subTitle">登入填表，極速即批貸款！</p>
        </div>
        <form [formGroup]="loginForm">
          <div
            [ngClass]="{
              input: true,
              input___error:
                phoneNumber &&
                (loginForm.get('phone_number')?.hasError('required') ||
                  loginForm.get('phone_number')?.hasError('pattern'))
            }"
          >
            <label><span>*</span>手機號碼</label>
            <input
              type="text"
              placeholder="香港手機號碼，如66668888"
              id="phone_number"
              formControlName="phone_number"
              (input)="resetError()"
            />
            <p class="input__errorMsg" *ngIf="loginForm.get('phone_number')?.hasError('required')">
              請輸入正確的手機號碼
            </p>
            <p class="input__errorMsg" *ngIf="loginForm.get('phone_number')?.hasError('pattern')">
              請輸入8位數字的香港手機號碼
            </p>
          </div>
          <div
            [ngClass]="{
              input: true,
              input___error:
                otp && (loginForm.get('otp')?.hasError('required') || loginForm.get('otp')?.hasError('pattern'))
            }"
            *ngIf="otpSent && loginForm.get('phone_number')!.valid"
          >
            <label><span>*</span>短信驗證碼</label>
            <div class="input__row">
              <input
                type="text"
                placeholder="******"
                formControlName="otp"
                (input)="resetError()"
                [readOnly]="otpVerified"
              />
              <!-- <a class="input__row__btn" (click)="verifyOTP()">Verify Otp</a> -->
            </div>
            <p class="input__errorMsg" *ngIf="loginForm.get('otp')?.hasError('required')">請輸入正確的短信驗證碼</p>
            <p class="input__errorMsg" *ngIf="loginForm.get('otp')?.hasError('pattern')">請輸入4位數字的短信驗證碼</p>
          </div>
          <!-- <div [ngClass]="{ input: true, input___error: password }">
            <label><span>*</span>密碼</label>
            <input
              type="password"
              placeholder="**************"
              id="password"
              formControlName="password"
              (input)="resetError()"
            />
            <p class="input__errorMsg">Please Enter Valid 密碼</p>
          </div> -->
          <!-- <div class="login__form__forgot">
            <a href="/forgot-password">忘記密碼?</a>
          </div> -->
          <input type="button" value="發送短信驗證碼" class="form-submit-btn" *ngIf="sendingOtp && !otpSent" />
          <input
            type="button"
            value="發送短信驗證碼"
            class="form-submit-btn"
            (click)="sendOTP()"
            *ngIf="!sendingOtp && !otpSent"
          />
          <input
            type="button"
            value="登入"
            class="form-submit-btn"
            (click)="onSubmit()"
            *ngIf="otpSent && loginForm.get('phone_number')!.valid && !otpVerified"
          />
          <!-- <input type="button" value="登入" class="form-submit-btn" (click)="onSubmit()" /> -->
          <div class="login__form__signup">沒有賬號? <a href="/registration">立即登記</a></div>
        </form>
      </div>
    </app-form>
  </div>
  <app-footer />
</div>
