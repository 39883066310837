import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  // Set a session cookie
  setSessionCookie(name: string, value: string): void {
    document.cookie = `${name}=${encodeURIComponent(value)}; path=/`;
  }

  // Get the value of a cookie by name
  getCookie(name: string): string | null {
    const cookieArray = document.cookie.split('; ');
    for (const cookie of cookieArray) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  // Delete a cookie by name
  deleteCookie(name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}
