import { Component } from '@angular/core';

@Component({
  selector: 'app-img-text',
  templateUrl: './img-text.component.html',
  styleUrls: ['./img-text.component.scss']
})
export class ImgTextComponent {

}
