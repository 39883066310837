<div class="blog-detail">
  <app-header />
  <div class="blog-detail__cont container">
    <div class="blog-detail__cont__det">
      <div class="blog-detail__cont__det__top">
        <div class="blog-detail__cont__det__top__detail">
          <p class="blog-detail__cont__det__top__detail__published">Published 20 Jan 2022</p>
          <h1
            class="blog-detail__cont__det__top__detail__title"
            *ngIf="blogDetailData !== undefined && blogDetailData.title !== undefined"
          >
            {{ blogDetailData.title }}
          </h1>
          <p
            class="blog-detail__cont__det__top__detail__desc"
            *ngIf="blogDetailData !== undefined && blogDetailData.discription !== undefined"
          >
            {{ blogDetailData.discription }}
          </p>
          <div class="blog-detail__cont__det__top__detail__row">
            <p
              class="blog-detail__cont__det__top__detail__row__tag blog-detail__cont__det__top__detail__row__tag___green"
              *ngIf="blogDetailData !== undefined && blogDetailData.blog_tag1 !== undefined"
            >
              {{ blogDetailData.blog_tag1 }}
            </p>
            <p
              class="blog-detail__cont__det__top__detail__row__tag blog-detail__cont__det__top__detail__row__tag___yellow"
              *ngIf="blogDetailData !== undefined && blogDetailData.blog_tag2 !== undefined"
            >
              {{ blogDetailData.blog_tag2 }}
            </p>
            <p
              class="blog-detail__cont__det__top__detail__row__tag blog-detail__cont__det__top__detail__row__tag___red"
              *ngIf="blogDetailData !== undefined && blogDetailData.blog_tag3 !== undefined"
            >
              {{ blogDetailData.blog_tag3 }}
            </p>
          </div>
        </div>
        <img
          [src]="blogDetailData.banner_image"
          alt="banner"
          *ngIf="blogDetailData !== undefined && blogDetailData.banner_image !== undefined"
        />
      </div>
      <div class="blog-detail__cont__det__bottom">
        <div class="blog-detail__cont__det__bottom__cont">
          <div class="blog-detail__cont__det__bottom__cont__detail" [innerHTML]="sanitizedHtml">
            <!-- <h2>Introduction</h2>
            <p>
              Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget
              vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate
              arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.<br />
              Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed
              auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at
              donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.
            </p>
            <img src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/blog-detail-img-1.jpg" alt="blog img" />
            <p>
              Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget
              consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
              velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.
            </p>
            <p>
              Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet
              pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada
              fringilla.<br />
              Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed
              condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam.
              Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec
              porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc
              lectus in tellus, pharetra, porttitor.<br />
              Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget
              consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
              velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.
            </p>
            <h2>Software and tools</h2>
            <p>
              Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget
              vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate
              arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.<br />
              Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed
              auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at
              donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.
            </p>
            <h2>Other resources</h2>
            <p>
              Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget
              diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at
              diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis
              auctor vulputate hac elementum gravida cursus dis.<br />
              1.Lectus id duis vitae porttitor enim gravida morbi. 2.Eu turpis posuere semper feugiat volutpat elit,
              ultrices suspendisse. Auctor vel in vitae placerat. 3.Suspendisse maecenas ac donec scelerisque diam sed
              est duis purus.
            </p>
            <img src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/blog-detail-img-1.jpg" alt="blog img" />
            <p>
              Lectus leo massa amet posuere. Malesuada mattis non convallis quisque. Libero sit et imperdiet bibendum
              quisque dictum vestibulum in non. Pretium ultricies tempor non est diam. Enim ut enim amet amet integer
              cursus. Sit ac commodo pretium sed etiam turpis suspendisse at.<br />
              Tristique odio senectus nam posuere ornare leo metus, ultricies. Blandit duis ultricies vulputate morbi
              feugiat cras placerat elit. Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit
              accumsan. Cursus viverra aenean magna risus elementum faucibus molestie pellentesque. Arcu ultricies sed
              mauris vestibulum.
            </p> -->
          </div>
          <div
            class="blog-detail__cont__det__bottom__cont__conclusion"
            *ngIf="blogDetailData !== undefined && blogDetailData.conclusion !== undefined"
          >
            <div class="blog-detail__cont__det__bottom__cont__conclusion__title">Conclusion</div>
            <p>
              {{ blogDetailData.conclusion }}
            </p>
          </div>
          <div class="blog-detail__cont__det__bottom__cont__footer">
            <div class="blog-detail__cont__det__bottom__cont__footer__author">
              <img
                [src]="blogDetailData.author_image"
                alt="author"
                width="56"
                height="56"
                *ngIf="blogDetailData !== undefined && blogDetailData.author_image !== undefined"
              />
              <div class="blog-detail__cont__det__bottom__cont__footer__author__detail">
                <p
                  class="blog-detail__cont__det__bottom__cont__footer__author__detail__name"
                  *ngIf="blogDetailData !== undefined && blogDetailData.author_name !== undefined"
                >
                  {{ blogDetailData.author_name }}
                </p>
                <p
                  class="blog-detail__cont__det__bottom__cont__footer__author__detail__des"
                  *ngIf="blogDetailData !== undefined && blogDetailData.author_designation !== undefined"
                >
                  {{ blogDetailData.author_designation }}
                </p>
              </div>
            </div>
            <!-- <div class="blog-detail__cont__det__bottom__cont__footer__social">
              <a href="" class="blog-detail__cont__det__bottom__cont__footer__social__copy">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_474_53629)">
                    <path
                      d="M4.16699 12.4998C3.39042 12.4998 3.00214 12.4998 2.69585 12.373C2.28747 12.2038 1.96302 11.8794 1.79386 11.471C1.66699 11.1647 1.66699 10.7764 1.66699 9.99984V4.33317C1.66699 3.39975 1.66699 2.93304 1.84865 2.57652C2.00844 2.26292 2.2634 2.00795 2.57701 1.84816C2.93353 1.6665 3.40024 1.6665 4.33366 1.6665H10.0003C10.7769 1.6665 11.1652 1.6665 11.4715 1.79337C11.8798 1.96253 12.2043 2.28698 12.3735 2.69536C12.5003 3.00165 12.5003 3.38993 12.5003 4.1665M10.167 18.3332H15.667C16.6004 18.3332 17.0671 18.3332 17.4236 18.1515C17.7372 17.9917 17.9922 17.7368 18.152 17.4232C18.3337 17.0666 18.3337 16.5999 18.3337 15.6665V10.1665C18.3337 9.23308 18.3337 8.76637 18.152 8.40985C17.9922 8.09625 17.7372 7.84128 17.4236 7.68149C17.0671 7.49984 16.6004 7.49984 15.667 7.49984H10.167C9.23357 7.49984 8.76686 7.49984 8.41034 7.68149C8.09674 7.84128 7.84177 8.09625 7.68198 8.40985C7.50033 8.76637 7.50033 9.23308 7.50033 10.1665V15.6665C7.50033 16.5999 7.50033 17.0666 7.68198 17.4232C7.84177 17.7368 8.09674 17.9917 8.41034 18.1515C8.76686 18.3332 9.23357 18.3332 10.167 18.3332Z"
                      stroke="#7DE7EB"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_474_53629">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Copy link
              </a>
              <a href="" class="blog-detail__cont__det__bottom__cont__footer__social__icons">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M14.3134 3.53516H16.6135L11.5884 9.18299L17.5 16.8685H12.8713L9.24592 12.2073L5.09769 16.8685H2.7962L8.17097 10.8275L2.5 3.53516H7.24621L10.5232 7.79567L14.3134 3.53516ZM13.5061 15.5147H14.7807L6.55367 4.81789H5.186L13.5061 15.5147Z"
                    fill="#CCF6F7"
                  />
                </svg>
              </a>
              <a href="" class="blog-detail__cont__det__bottom__cont__footer__social__icons">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_474_53636)">
                    <path
                      d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z"
                      fill="#CCF6F7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_474_53636">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a href="" class="blog-detail__cont__det__bottom__cont__footer__social__icons">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_474_53639)">
                    <path
                      d="M18.5236 0H1.47639C1.08483 0 0.709301 0.155548 0.432425 0.432425C0.155548 0.709301 0 1.08483 0 1.47639V18.5236C0 18.9152 0.155548 19.2907 0.432425 19.5676C0.709301 19.8445 1.08483 20 1.47639 20H18.5236C18.9152 20 19.2907 19.8445 19.5676 19.5676C19.8445 19.2907 20 18.9152 20 18.5236V1.47639C20 1.08483 19.8445 0.709301 19.5676 0.432425C19.2907 0.155548 18.9152 0 18.5236 0ZM5.96111 17.0375H2.95417V7.48611H5.96111V17.0375ZM4.45556 6.1625C4.11447 6.16058 3.7816 6.05766 3.49895 5.86674C3.21629 5.67582 2.99653 5.40544 2.8674 5.08974C2.73826 4.77404 2.70554 4.42716 2.77336 4.09288C2.84118 3.7586 3.0065 3.4519 3.24846 3.21148C3.49042 2.97107 3.79818 2.80772 4.13289 2.74205C4.4676 2.67638 4.81426 2.71133 5.12913 2.84249C5.44399 2.97365 5.71295 3.19514 5.90205 3.47901C6.09116 3.76288 6.19194 4.09641 6.19167 4.4375C6.19488 4.66586 6.15209 4.89253 6.06584 5.104C5.97959 5.31547 5.85165 5.50742 5.68964 5.66839C5.52763 5.82936 5.33487 5.95607 5.12285 6.04096C4.91083 6.12585 4.68389 6.16718 4.45556 6.1625ZM17.0444 17.0458H14.0389V11.8278C14.0389 10.2889 13.3847 9.81389 12.5403 9.81389C11.6486 9.81389 10.7736 10.4861 10.7736 11.8667V17.0458H7.76667V7.49306H10.6583V8.81667H10.6972C10.9875 8.22917 12.0042 7.225 13.5556 7.225C15.2333 7.225 17.0458 8.22083 17.0458 11.1375L17.0444 17.0458Z"
                      fill="#CCF6F7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_474_53639">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <app-recent-blog [blogData]="recentBlogData" />
  </div>
  <app-footer />
</div>
