<div class="benefits">
  <div class="benefits__cont container">
    <div class="benefits__box">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/benefits-1-1.png"
        alt="benefits-1"
        height="88"
        width="130"
      />
      <h2 class="benefits__box__title">免繁複文件(如樓契,入息證明)及可免壓力測試</h2>
    </div>
    <div class="benefits__box">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/benefits-2-1.png"
        alt="benefits-2"
        height="88"
        width="130"
      />
      <h2 class="benefits__box__title">最快24小時現金到手</h2>
    </div>
    <div class="benefits__box">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/benefits-3-1.png"
        alt="benefits-3"
        height="88"
        width="130"
      />
      <h2 class="benefits__box__title">接受任何物業種類, 不限樓齡</h2>
    </div>
    <div class="benefits__box">
      <img
        src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/benefits-4-1.png"
        alt="benefits-4"
        height="88"
        width="130"
      />
      <h2 class="benefits__box__title">豁免手續費,律師費及估價費</h2>
    </div>
  </div>
</div>
