<div class="about">
  <app-header />
  <app-img-text-reverse />
  <div class="about__loan">
    <h2 class="about__loan__title">填寫表格 立即申請</h2>
    <app-form>
      <div class="about__loan__form">
        <div class="about__loan__form__header">
          <h2 class="about__loan__form__title">貸款資料</h2>
          <p class="about__loan__form__subTitle">
            請先填寫以下所需資料，以加快審批過程。我們或有可能會聯絡閣下提供更多申請所需的資料。
          </p>
        </div>
        <form action="" [formGroup]="inquiryForm">
          <div
            [ngClass]="{
              input: true,
              input___error:
                phone_number &&
                (inquiryForm.get('phone_number')?.hasError('required') ||
                  inquiryForm.get('phone_number')?.hasError('pattern'))
            }"
          >
            <label><span>*</span>電話</label>
            <input type="number" placeholder="電話/WhatsApp" formControlName="phone_number" (input)="resetError()" />
            <p class="input__errorMsg" *ngIf="inquiryForm.get('phone_number')?.hasError('required')">
              Mobile number is required.
            </p>
            <p class="input__errorMsg" *ngIf="inquiryForm.get('phone_number')?.hasError('pattern')">
              Please enter a valid 8-digit mobile number.
            </p>
          </div>
          <div
            [ngClass]="{
              input: true,
              input___error:
                (phone_number && inquiryForm.get('email')?.hasError('required')) ||
                inquiryForm.get('email')?.hasError('email')
            }"
          >
            <label><span>*</span>電郵</label>
            <input type="text" placeholder="電郵" formControlName="email" (input)="resetError()" />
            <p class="input__errorMsg" *ngIf="inquiryForm.get('email')?.hasError('required')">Email is required.</p>
            <p class="input__errorMsg" *ngIf="inquiryForm.get('email')?.hasError('email')">
              Please enter a valid email.
            </p>
          </div>
          <div
            [ngClass]="{
              input: true,
              input___error: phone_number && inquiryForm.get('loan_amount')?.hasError('required')
            }"
          >
            <label><span>*</span>申請貸款金額</label>
            <input type="number" placeholder="$10000~$1000000" formControlName="loan_amount" (input)="resetError()" />
            <p class="input__errorMsg" *ngIf="inquiryForm.get('loan_amount')?.hasError('required')">
              Loan Amount is required.
            </p>
          </div>
          <div
            [ngClass]="{
              input: true,
              input___error: phone_number && inquiryForm.get('loan_tenor')?.hasError('required')
            }"
          >
            <label><span>*</span>供款期</label>
            <input type="number" placeholder="1 ~ 60個月" formControlName="loan_tenor" (input)="resetError()" />
            <p class="input__errorMsg" *ngIf="inquiryForm.get('loan_tenor')?.hasError('required')">
              Contribution Period is required.
            </p>
          </div>
          <input type="button" value="提交" class="form-submit-btn" (click)="onSubmit()" />
        </form>
      </div>
    </app-form>
  </div>
  <app-benefits />
  <app-personal-loan-qa />
</div>
<app-footer />
<div *ngIf="success" class="aboutSuccess">
  <img
    src="https://dwoytywxtnttv.cloudfront.net/FrontendStatics/images/success-msg.png"
    alt="submit successfully"
    width="384"
    height="200"
  />
</div>
