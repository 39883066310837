<div class="privacy">
  <app-header />
  <div class="privacy__cont container">
    <h2 class="privacy__cont__title">私隱政策</h2>
    <div class="privacy__cont__all">
      <div class="privacy__cont__all__top">
        <div class="privacy__cont__all__title">
          1. 引言
          <p>
            1.1　此聲明乃採納為 CREDIT PRO FINANCE
            LIMITED信貸有限公司（下稱「本公司」）的私隱政策聲明（下稱「本聲明」）。訂立本聲明的目的，是為確立本公司全力執行保障個人資料的政策及實務，以遵守個人資料（私隱）條例（下稱「該條例」）各項條款及條文，及由個人資料私隱專員就該條例而頒布的指引。
            1.2　本公司的中國及海外分行及附屬公司，亦會因應所在國家或地區的特定法規或監管條文而自行制定合適的政策及實務，以確保遵守適用於有關地區的個人資料保障法規或監管要求。
          </p>
          <br />
          2. 本公司持有的個人資料的種類
          <p>
            2.1　概括而言，本公司持有的個人資料有兩大類，包括客戶的個人資料及與僱傭有關的個人資料。
            2.2　本公司持有的客戶個人資料可能包括下列各項： a.
            客戶及其配偶的姓名和地址、職業、聯絡詳情、出生日期和國籍、其身份證及／或護照號碼及證件發出日期和地點； b.
            客戶及其配偶現時的僱主、職位性質、年薪及其他福利； c. 客戶及其配偶持有的物業、資產或投資的詳情； d.
            客戶及其配偶所有的其他資產或負債（實有或或然）的詳情； e.
            本公司在延續與客戶正常業務關係中獲得的資料（例如，當客戶開出支票或在一般情況下以口頭或書面形式與本公司溝通時，本公司亦會收集客戶的資料，當中可能以文書形式或電話錄音系統收集）；
            f. 就要求追收任何客戶拖欠本公司款項而由諮詢人、信貸資料服務機構或收數公司提供的信用狀況資料；及 g.
            可透過公共領域取得的資料。 2.3　本公司持有與僱傭有關的個人資料可能包括下列各項： a.
            僱員或準僱員及其配偶的姓名和地址、聯絡詳情、出生日期和國籍、其身份證及／或護照號碼及證件發出日期和地點； b.
            在遴選過程中進一步匯集的求職者資料，可能包括從其現任僱主或前僱主或其他來源取得的評介，藉以評估求職者是否勝任有關職位；
            c.
            本公司在延續僱傭關係收集更多關於僱員的資料，可能包括向僱員發放的工資及提供福利的記錄，僱員所擔任的職位、調職及培訓記錄，體格檢驗、病假及其他醫療補償申索記錄及僱員的工作表現評核報告；
            d. 本公司為履行對前僱員的責任或履行某些條例所規定的法律責任而可能保留前僱員的相關個人資料；及 e.
            可透過公共領域取得的資料。 2.4　本公司或會持有鑑於經驗及其業務特別性質所需的其他種類的個人資料。
          </p>
          <br />
          3. 使用個人資料的目的
          <p>
            3.1　客戶在開立或延續戶口、建立或延續信貸或其他相關服務時，需要不時向本公司提供有關的資料。
            3.2　客戶與本公司在延續日常業務或其他金融關係中，本公司亦會收集客戶的資料。
            3.3　客戶的資料可被用作下列用途： a.
            處理及考慮產品及服務的申請及為客戶提供產品、服務和信貸融通所涉及的日常運作； b.
            在客戶申請信貸時進行的信貸調查，及通常每年進行一次或以上的定期或特別信貸覆核； c.
            設立及維持本公司的信貸評分模式； d. 協助其他財務機構作信用檢查及追討債務； e. 確保客戶持續維持可靠信用； f.
            設計供客戶使用的財務服務或有關產品； g. 推廣服務、產品及其他標的（詳情請參閱《個人資料收集（客戶）聲明
            》第（7）段）； h. 核實任何其他客戶或第三方所提供的數據或資料； i.
            確定本公司對客戶或客戶對本公司的欠債金額； j.
            執行客戶向本公司之應負責任，包括但不限於向客戶及為客戶的責任提供抵押的人士追收欠款； k.
            履行根據下列適用於本公司或其任何分行或本公司或其任何分行被期望遵守的就披露及使用資料的義務、規定或安排： (i)
            不論於香港特別行政區（下稱「香港」）境內或境外及不論目前或將來存在的對其具法律約束力或適用的任何法律（例如稅務條例及當中的條款）；
            (ii)
            不論於香港境內或境外及不論目前或將來存在的任何法律、監管、政府、稅務、執法或其他機關，或金融服務供應商的自律監管或行業組織或協會作出或發出的任何指引或指導（例如稅務局作出或發出的指引或指導）；
            (iii)
            本公司或其任何分行因其位於或跟相關本地或外地的法律、監管、政府、稅務、執法或其他機關，或自律監管或行業組織或協會的司法管轄區有關的金融、商業、業務或其他利益或活動，而向該等本地或外地的法律、監管、政府、稅務、執法或其他機關，或金融服務供應商的自律監管或行業組織或協會承擔或被彼等施加的任何目前或將來的合約或其他承諾；
            l.
            遵守與本公司屬同一集團之公司為符合制裁或預防或偵測清洗黑錢、恐怖分子融資活動或其他非法活動的任何方案就於與本公司屬同一集團之公司內共用資料及資訊及／或資料及資訊的任何其他使用而指定的任何義務、要求、政策、程序、措施或安排；
            m.
            讓本公司的實際或建議承讓人，或就本公司對客戶享有的權利的參與人或附屬參與人評核其擬承讓，參與或附屬參與的交易；及
            n. 與上述有關的用途。 3.4　僱員及可能成為本公司僱員的人士的資料，可被用作下列用途： a. 處理受聘申請； b.
            釐定及檢討工資、獎金及其他福利； c.
            根據本公司內部政策或監管規定進行適當人選評估或考慮升職、培訓、調用或調職； d.
            評審員工貸款及其他福利和享有權的資格及有關的管理； e. 為員工出具諮詢証明書； f.
            為員工申領與僱傭直接有關／相關的中介人或持牌資格； g. 監察遵守本公司內部規則的情況； h.
            本公司為履行根據下列適用於本公司或其任何分行或本公司或其任何分行被期望遵守的就披露及使用資料的義務、規定或安排：
            (i) 不論於香港境內或境外及不論目前或將來存在的對其具法律約束力或適用的任何法律；或 (ii)
            不論於香港境內或境外及不論目前或將來存在的任何法律、監管、政府、稅務、執法或其他機關，或金融服務供應商的自律監管或行業組織或協會作出或發出的任何指引或指導；
            i. 就懷疑詐騙案、不當行為及犯罪活動進行調查；及 j. 作人力資源管理或與上述有關的用途。
          </p>
          <br />
          4. 個人資料的保安
          <p>
            本公司的政策是為確保個人資料的保安及防止資料被未獲准許或意外的查閱、處理、刪除、喪失或使用，就個人資料因應其敏感程度及考慮如此等事情發生便能做成的損害程度提供適度的保障。為達到適當程度的保安，本公司的一貫做法是透過提供安全的儲存設施（包括在資料存置設備實施保安措施）來嚴格限制資料被查閱及處理。本公司亦會採取措施以確保能查閱該等資料的人士具備良好操守、審慎態度及辦事能力。個人資料只會以妥善保安的方式傳送，從而防止資料被未獲准許或意外的查閱。如本公司聘用（不論是在香港或香港以外聘用）資料處理者，以代本公司處理個人資料，本公司將採用合約規範方法或其他方法，以防止轉移予該資料處理者作處理的個人資料被未獲准許或意外的查閱、處理、刪除、喪失或使用。
          </p>
          <br />
          5. 個人資料的準確性
          <p>
            本公司的政策是採取所有切實可行的步驟以確保所有經由本公司收集及處理的個人資料在顧及有關的個人資料被使用於或會被使用於的目的下均為準確。本公司會實施適當的程序以定期核對及更新所有個人資料。倘若本公司所持有的個人資料含有意見聲明，本公司會採取一切合理切實可行的步驟，以確保任何聲言是支持該項意見聲明的事實，均屬正確。
          </p>
          <br />
          6. 個人資料的收集
          <p>
            6.1　在收集個人資料的過程中，本公司會向有關人士提供一份個人資料收集聲明，述明收集資料的目的、將獲轉交資料的人士的身分類別、查閱及改正資料的權利，以及其他有關資料。
            6.2　本公司於使用取自公共領域的個人資料前，會留意該等資料存放於公共領域的原來使用目的（例如法例訂明設立某公共登記冊的目的）、相關使用限制（如有）及有關人士在個人資料私隱方面的合理期望。
            6.3　有關本公司從互聯網收集個人資料，本公司會採納以下實務： a.　網上保安
            本公司會按照嚴格的保安及保密標準保障在互聯網提供給本公司的任何資料，並採用加密的方式在互聯網上傳輸敏感性數據，以保障個人的私隱。
            b.　網上改正資料
            透過網上設施提供給本公司的個人資料一經呈交，便未必能在網上刪除、改正或更新。使用者如未能在網上作出刪除、改正或更新，便須聯絡本公司相關員工、部門或分行。
            c.　網上保留資料
            在網上收集的個人資料會被轉送到本公司相關員工、部門或分行處理。個人資料一般不會存置於本公司網站伺服器超過六個月。
            6.4　Cookies、Tags及Web Logs的使用
            Cookies是由網站伺服器傳送至瀏覽器的小段資訊，這些資料儲存於電腦硬碟中，使網站伺服器能於稍後再從瀏覽器內讀取。這有助網站保存某位特定使用者的資料。Cookies被設計成只可讓發出的網站讀取，但不能用作取得使用者的硬碟資料、電郵地址或收集使用者的敏感性資料。基於以下目的，本公司使用Cookies、Tags及Web
            Logs來識別使用者的網頁瀏覽器： a.　身份識別
            本公司不會把使用者的敏感性資料存置於Cookies內。當使用者瀏覽本公司網站時，所有聯系將會利用Cookies去識別使用者身份。
            b.　資料分析 使用者瀏覽本公司的網站時，本公司可能透過Cookies/Tags/Web
            Logs等技術收集有關瀏覽記錄以提供資料分析。該等記錄是不記名的集體統計資料，並不包括任何個人身份資料。本公司收集有關記錄資料，主要用於更好地瞭解使用者統計數據、興趣及使用模式，及提高本公司網上推廣的效率。
            資料可能會經本公司轉移至第三方公司（例如，網頁流量追蹤及報告、網上廣告刊登等的外部服務供應商）或由第三方公司代本公司收集以進行以上用途。而第三方公司不會把該記錄再轉移予其他各方。該等記錄是不記名的集體統計資料，並不包括任何個人身份資料。
            大多數網絡瀏覽器初始設定均為接受Cookies。使用者可以透過變更網絡瀏覽器的設定選擇『不接受』Cookies，但此舉可能導致使用者無法使用本公司的網上服務及本公司網站上的某些功能無法正常運作。本公司保留所收集資料的時間取決於收集該資料的原始目的或與其直接相關的目的，以及為滿足任何適用法例及合約要求。
            透過Cookies/Tags/Web Logs等技術收集的資料一般不會保留超過3年。
            6.5　本公司為一般保安目的於本公司範圍安裝錄影模式閉路電視系統以保障客戶及員工人身安全、業務資產、知識產權或其他財產。有關閉路電視系統記錄只會由被授權人士查閱或使用。本公司可能在有需要對任何法律程序作出回應，或對任何事故或投訴等情況作出調查時，披露有關閉路電視系統記錄予第三方，例如監管機構或執法機關。除上文所述情況外，本公司會按照相關保留政策及指引刪除閉路電視系統記錄，並根據本聲明採取合適的保安措施保障有關記錄。
          </p>
          <br />
          7. 查閱資料要求及改正資料要求
          <p>
            7.1　本公司的政策為按照該條例的規定，依從及處理一切查閱資料及改正資料要求；及讓所有有關職員熟悉有關的規定，以協助各人士作出有關要求。
            7.2　本公司或會在符合該條例或由個人資料私隱專員就該條例而頒布之指引的規定下，就查閱資料要求徵收費用。本公司只可收取與依從查閱資料要求直接有關及必須之費用。倘若任何提出查閱資料要求的人士要求本公司提供按早前的查閱資料要求提供過的個人資料的額外複本，本公司或會收取費用以全數彌補因提供該額外複本而涉及的行政成本或其他成本的費用。
            7.3　有關查閱資料及改正資料的要求，可向本公司資料保障主任或其他相關指定人員提出。
          </p>
          <br />
          8. 個人資料之存檔
          <p>
            8.1　本公司會採取所有一切合理切實可行的步驟，以確保個人資料的保存時間不超過將其保存以貫徹該資料被使用於或會被使用於的目的所需的時間。本公司在結束帳戶、終止服務或員工離職後一般會持有有關客戶及僱員的資料7年或按照有關法律和法規所規定的期限。
            8.2　有關本公司收集求職者的個人資料，除非有具體理由規定本公司必須保留該等資料一段較長期間（例如按照有關法律和法規所規定的期限），本公司或會為日後招聘的用途在求職者落選後保留該等資料2年。
            8.3　如本公司聘用（不論是在香港或香港以外聘用）資料處理者，以代本公司處理個人資料，本公司將採用合約規範方法或其他方法，以防止轉移予該資料處理者作處理的個人資料的保存時間超過處理該資料所需的時間。
          </p>
          <br />
          9. 其他實務
          <p>
            為確保依從該條例規定，本公司備有： a.　資料記錄簿，即該條例第27條所規定的記錄簿；
            b.　內部政策及指引以供本公司員工使用，以確保各員工遵守該條例的規定；
            c.　查閱資料要求表格及改正資料要求表格，供任何人士申請查閱及改正本公司所持有關於他們的個人資料。 10.
            資料保障主任的委任
            10.1　本公司已委任資料保障主任，以負責統籌及監察該條例及本公司保障個人資料政策的遵守情況。
            10.2　資料保障主任的聯絡資料如下︰
          </p>
          <br />
          <p>
            地址：尖沙咀中港城 3座 12樓11室 Room 11, 12/F, Tower 3, China Hong Kong City, Tsim Sha Tsui<br />
            電話：2153 1523<br />
            Email：CreditPro2023@gmail.com
          </p>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</div>
